import React from "react";
import {generateClassName} from "../../util/generate";

type Props = {
    type: string;
}

export default function Skeleton(props: Props) {
    return (
        <div className={generateClassName("skeleton", props.type)}>
            <div/>
        </div>
    );
}