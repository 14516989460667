import BaseItemCard, {BaseItemCardProps} from "./BaseItemCard";
import React from "react";
import SolidIcon from "../icons/SolidIcon";
import {ICONS} from "../../util/constants";
import {formatDate, formatTime, formatWeather} from "../../util/helpers";
import Entry from "../../data/Entry";

type EntryItemCardProps = BaseItemCardProps & {
    entry: Entry;
}

export default class EntryItemCard extends BaseItemCard<EntryItemCardProps> {
    protected readonly type = "entry"

    protected renderInner() {
        const formatNumberOfCompanies = (numberOfCompanies: number) => {
            if (numberOfCompanies <= 0) {
                return "keine Firmen";
            } else if (numberOfCompanies === 1) {
                return "eine Firma";
            } else {
                return `${numberOfCompanies} Firmen`;
            }
        }

        const formatNumberOfFotos = (numberOfFotos: number) => {
            if (numberOfFotos <= 0) {
                return "keine Fotos";
            } else if (numberOfFotos === 1) {
                return "ein Foto";
            } else {
                return `${numberOfFotos} Fotos`;
            }
        }

        return (
            <div>
                <div className="header">
                    <div className="icon-wrapper">
                        <SolidIcon name={ICONS.date}/>
                    </div>
                    <div className="title-wrapper">
                        <h1>{formatDate(this.props.entry.start)}</h1>
                    </div>
                </div>

                <div className="details">
                    <span className="detail">
                        {`${formatTime(this.props.entry.start)} bis ${formatTime(this.props.entry.end)}`}
                    </span>

                    <span className="detail">{formatWeather(this.props.entry.weather)}</span>
                    <span className="detail">{`${this.props.entry.temperature}°C`}</span>
                    <span className="detail">{formatNumberOfCompanies(this.props.entry.companies.length)}</span>
                    <span className="detail">{formatNumberOfFotos(this.props.entry.fotos.length)}</span>
                </div>
            </div>
        );
    }
}