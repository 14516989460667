import BaseSheet, {BaseSheetProps} from "./BaseSheet";
import React from "react";
import ProcessingButton from "../buttons/ProcessingButton";
import {generateClassName} from "../../util/generate";
import device from "../../util/device";

type Props = BaseSheetProps & {
    description: string;
    onConfirm: () => Promise<any>;
}

export default class ConfirmationSheet extends BaseSheet<Props> {
    protected readonly type = "confirmation";

    public constructor(props: Props) {
        super(props);

        this.confirm = this.confirm.bind(this);
        this.close = this.close.bind(this);
    }

    protected renderInner() {
        return <p>{this.props.description}</p>;
    }

    protected renderButtons() {
        return (
            <div>
                <button
                    className={generateClassName("light", device.isSmall() ? "small" : "")}
                    onClick={this.close}
                >
                    abbrechen
                </button>

                <ProcessingButton
                    className={generateClassName("primary", device.isSmall() ? "small" : "")}
                    onClick={this.confirm}
                    onFinish={this.close}
                >
                    bestätigen
                </ProcessingButton>
            </div>
        );
    }

    private async confirm() {
        await this.props.onConfirm();
    }
}