import React from "react";
import SignUpForm from "../components/forms/SignUpForm";
import User from "../data/User";

export default function SignUpPage() {
    User.redirectSignedIn();

    return (
        <main>
            <SignUpForm title="Konto erstellen" actionLabel="registrieren" className="major"/>
        </main>
    );
}