import BaseComponent, {BaseProps} from "../BaseComponent";
import React from "react";
import ImageInput from "./ImageInput";
import {toBase64} from "../../util/helpers";

type Props = BaseProps & {
    image?: string;
    onAdd: (imageData: string) => void;
    onRemove: () => void;
}

type State = {
    image?: string;
}

export default class SignatureImageInput extends BaseComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            image: props.image
        }
    }


    public render() {
        const add = async (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files) {
                const file = Array.from(event.target.files)[0];
                const base64 = await toBase64(file);

                this.setState({image: base64});
                this.props.onAdd(base64);
            }
        }

        const remove = () => {
            this.setState({image: ""});
            this.props.onRemove();
        }

        const renderButton = () => {
            if (this.state.image) {
                return (
                    <button className="wide light" onClick={remove}>
                        Unterschrift entfernen
                    </button>
                );
            } else {
                return (
                    <ImageInput onChange={add}>
                        Unterschrift hinzufügen
                    </ImageInput>
                );
            }
        }

        const renderSignature = () => {
            if (this.state.image) {
                return <img src={this.state.image} alt="Unterschrift"/>
            }
        }

        return (
            <div id={this.id} className={this.classes("input signature")}>
                {renderButton()}
                {renderSignature()}
            </div>
        );
    }
}