import React from "react";
import ForgotPasswordForm from "../components/forms/ForgotPasswordForm";
import User from "../data/User";

export default function ForgotPasswordPage() {
    User.redirectSignedIn();

    return (
        <main>
            <ForgotPasswordForm title="Passwort vergessen" actionLabel="zurücksetzen" className="major"/>
        </main>
    );
}