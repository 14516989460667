import BaseOverview, {BaseOverviewProps, BaseOverviewState} from "./BaseOverview";
import React from "react";
import {fillRouteParameters, getUrlParameter, setEntryExportConfiguration} from "../../util/helpers";
import Skeleton from "../util/Skeleton";
import {generateClassName, generateKey} from "../../util/generate";
import EntryItemCard from "../item-cards/EntryItemCard";
import {ICONS, ROUTES} from "../../util/constants";
import ConfirmationSheet from "../sheets/ConfirmationSheet";
import Empty from "../util/Empty";
import device from "../../util/device";
import OutlinedIcon from "../icons/OutlinedIcon";
import ShareProjectSheet from "../sheets/ShareProjectSheet";
import MonthSelector from "../util/MonthSelector";
import CircularProgressIndicator from "../indicators/CircularProgressIndicator";
import CreateEntrySheet from "../sheets/CreateEntrySheet";
import EditEntrySheet from "../sheets/EditEntrySheet";
import Entry from "../../data/Entry";
import Project from "../../data/Project";

const NUMBER_OF_ENTRIES_KEY = "number-of-entries";

type State = BaseOverviewState<Entry> & {
    project: Project | null;
    showShareProject: boolean;
    showCreateProject: boolean;
    showEditProject: boolean;
    month: Date;
}

export default class EntryOverview extends BaseOverview<Entry, BaseOverviewProps, State> {
    protected readonly options = [
        {action: this.exportSelected.bind(this), icon: ICONS.export, label: "exportieren", singleSelectionOnly: false},
        {action: this.showEditEntry.bind(this), icon: ICONS.edit, label: "bearbeiten", singleSelectionOnly: true},
        {action: this.showDeleteSelected.bind(this), icon: ICONS.delete, label: "löschen", singleSelectionOnly: false},
    ];
    private readonly projectIdentifier = getUrlParameter(1);

    public constructor(props: BaseOverviewProps) {
        super(props);

        this.state = {
            items: [],
            selected: [],
            loading: true,
            project: null,
            showDeleteConfirmation: false,
            showShareProject: false,
            showCreateProject: false,
            showEditProject: false,
            month: new Date()
        };

        this.delete = this.delete.bind(this);
        this.showShareProject = this.showShareProject.bind(this);
        this.hideShareProject = this.hideShareProject.bind(this);
        this.showCreateEntry = this.showCreateEntry.bind(this);
        this.hideCreateEntry = this.hideCreateEntry.bind(this);
        this.showEditEntry = this.showEditEntry.bind(this);
        this.hideEditEntry = this.hideEditEntry.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
    }

    private static getAllNumbersOfEntries() {
        const json = localStorage.getItem(NUMBER_OF_ENTRIES_KEY);

        if (json) {
            return JSON.parse(json);
        } else {
            return {};
        }
    }

    public async componentDidMount() {
        await super.componentDidMount();
        const identifier = getUrlParameter(1);

        await Project.get(identifier).then(project => {
            document.title = project.title;

            this.setState({
                project: project
            });
        });
    }

    protected renderHeader() {
        return (
            <div>
                <button
                    className={generateClassName("primary", device.isSmall() ? "small" : "")}
                    onClick={this.showCreateEntry}
                >
                    Neuer Eintrag
                </button>

                <button
                    className={generateClassName(device.isSmall() ? "icon small dark" : "light")}
                    onClick={this.showShareProject}
                >
                    {device.isSmall() ? <OutlinedIcon name={ICONS.share}/> : "teilen"}
                </button>

                <button
                    className={generateClassName(device.isSmall() ? "icon small dark" : "light")}
                    onClick={() => {
                        window.location.href = fillRouteParameters(ROUTES.editProject, this.projectIdentifier);
                    }}
                >
                    {device.isSmall() ? <OutlinedIcon name={ICONS.edit}/> : "bearbeiten"}
                </button>

                <MonthSelector value={this.state.month} onChange={this.onMonthChange}/>
            </div>
        );
    }

    protected renderContent() {
        const entries = this.currentEntries();

        if (this.state.loading && entries.length === 0) {
            const numberOfEntries = this.getNumberOfEntries();
            const skeletons: React.ReactNode[] = [];

            for (let i = 0; i < numberOfEntries; i++) {
                skeletons.push(<Skeleton key={generateKey()} type="item-card entry"/>);
            }

            return (
                <div className="wrapper">
                    {skeletons.length > 0 ? skeletons :
                        <CircularProgressIndicator className="primary absolute-centered"/>}
                </div>
            );
        } else {
            return (
                <div className="wrapper">
                    {this.renderItems()}
                </div>
            );
        }
    }

    protected renderDeleteConfirmation() {
        if (this.state.showDeleteConfirmation) {
            return <ConfirmationSheet
                title={(this.state.selected.length > 1 ? "Einträge" : "Eintrag") + " löschen"}
                onClose={this.hideDeleteSelected}
                description={`Bist du dir sicher, dass du ${this.state.selected.length > 1 ? "diese Einträge" : "diesen Eintrag"} löschen möchtest? Dieser Schritt kann nicht rückgängig gemacht werden.`}
                onConfirm={this.delete}
            />;
        }
    }

    protected renderEmpty() {
        return <Empty
            title="Keine Einträge"
            description="Dieses Projekt hat noch keine Einträge. Klicke auf den Button, um den ersten Eintrag zu erstellen."
            action={{
                label: "Eintrag erstellen",
                onClick: this.showCreateEntry
            }}
        />;
    }

    protected renderAdditionalSections() {
        return (
            <div>
                {this.renderDeleteConfirmation()}
                {this.renderCreateEntry()}
                {this.renderShareProject()}
                {this.renderEditEntry()}
            </div>
        );
    }

    protected async fetch() {
        return Entry.getCurrentProjectEntries(this.projectIdentifier, this.state.month);
    }

    protected open(...identifiers: string[]) {
        setEntryExportConfiguration(this.projectIdentifier, identifiers);
        window.location.href = ROUTES.export;
    }

    protected async delete() {
        for (const identifier of this.state.selected) {
            const entry = this.state.items.find(entry => entry.identifier === identifier);

            if (entry) {
                await entry.delete();
            }
        }

        this.setState({
            items: this.state.items.filter(item => !this.state.selected.includes(item.identifier))
        });
    }

    protected sortItems() {
        return this.state.items.sort((a, b) => a.start.getTime() - b.start.getTime());
    }

    private renderItems() {
        const entries = this.currentEntries();
        this.setNumberOfEntries();

        if (entries.length > 0) {
            return entries.map(item => <EntryItemCard
                key={item.identifier}
                identifier={item.identifier}
                selected={this.isSelected(item.identifier)}
                onClick={() => this.handleClick(item.identifier)}
                onDoubleClick={() => this.handleDoubleClick(item.identifier)}
                onHold={() => this.handleHold(item.identifier)}
                entry={item}
            />);
        } else {
            return <Empty title="Keine Einträge" description="Für diesen Monat gibt es keine Einträge."/>;
        }
    }

    private renderShareProject() {
        if (this.state.showShareProject && this.state.project) {
            return <ShareProjectSheet
                project={this.state.project}
                title="Projekt teilen"
                onClose={this.hideShareProject}
            />;
        }
    }

    private renderCreateEntry() {
        const addEntry = (entry: Entry) => {
            this.setState({
                items: [...this.state.items, entry],
                selected: []
            });
        }

        if (this.state.showCreateProject && this.state.project) {
            return <CreateEntrySheet
                project={this.state.project}
                lastEntry={this.getLastEntry()}
                title="Eintrag erstellen"
                onClose={this.hideCreateEntry}
                onFinish={addEntry}
            />;
        }
    }

    private renderEditEntry() {
        const editEntry = (entry: Entry) => {
            const entries = this.state.items;

            for (let i = 0; i < entries.length; i++) {
                if (entries[i].identifier === entry.identifier) {
                    this.setState({
                        items: entries,
                        selected: []
                    });

                    break;
                }
            }
        }

        if (this.state.showEditProject && this.state.selected.length > 0 && this.state.project) {
            const entry = this.state.items.find(entry => entry.identifier === this.state.selected[0]);

            if (entry) {
                return <EditEntrySheet
                    title="Bearbeiten"
                    onClose={this.hideEditEntry}
                    onFinish={editEntry}
                    project={this.state.project}
                    lastEntry={this.getLastEntry()}
                    entry={entry}
                />;
            }
        }
    }

    private exportSelected() {
        this.open(...this.state.selected);
    }

    private showShareProject() {
        this.setState({
            showShareProject: true
        });
    }

    private hideShareProject() {
        this.setState({
            showShareProject: false
        });
    }

    private showCreateEntry() {
        this.setState({
            showCreateProject: true
        });
    }

    private hideCreateEntry() {
        this.setState({
            showCreateProject: false
        });
    }

    private showEditEntry() {
        this.setState({
            showEditProject: true
        });
    }

    private hideEditEntry() {
        this.setState({
            showEditProject: false
        });
    }

    private onMonthChange(month: Date) {
        this.setState({
            month: month
        }, this.update);
    }

    private currentEntries() {
        const month = this.state.month.getMonth();
        const year = this.state.month.getFullYear();

        return this.sortItems().filter(item => {
            return item.start.getMonth() === month && item.start.getFullYear() === year;
        });
    }

    private getNumberOfEntries() {
        const numberOfEntries = EntryOverview.getAllNumbersOfEntries();

        if (numberOfEntries[this.projectIdentifier]) {
            return parseInt(numberOfEntries[this.projectIdentifier][this.getDateKey()] || "0");
        } else {
            return 0;
        }
    }

    private setNumberOfEntries() {
        const numberOfEntries = EntryOverview.getAllNumbersOfEntries();

        if (!numberOfEntries[this.projectIdentifier]) {
            numberOfEntries[this.projectIdentifier] = {};
        }

        numberOfEntries[this.projectIdentifier][this.getDateKey()] = this.currentEntries().length;
        localStorage.setItem(NUMBER_OF_ENTRIES_KEY, JSON.stringify(numberOfEntries));
    }

    private getDateKey() {
        return this.state.month.toDateString().replace(/\s/g, "-");
    }

    private getLastEntry() {
        return this.state.items.length > 0 ? this.state.items.reduce((prev, current) => {
            return (prev.end.getTime() > current.end.getTime()) ? prev : current
        }) : null;
    }
}