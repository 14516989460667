import BaseSheet, {BaseSheetProps} from "./BaseSheet";
import React from "react";
import TextInput from "../inputs/TextInput";
import Validation from "../../util/validation";
import {ICONS, LABELS, PLACEHOLDERS} from "../../util/constants";
import SolidIcon from "../icons/SolidIcon";
import OutlinedIcon from "../icons/OutlinedIcon";
import ProcessingButton from "../buttons/ProcessingButton";
import NegativePopup from "../popup/NegativePopup";
import CircularProgressIndicator from "../indicators/CircularProgressIndicator";
import Project from "../../data/Project";
import BaseDocument from "../../data/BaseDocument";
import User from "../../data/User";

type Props = BaseSheetProps & {
    project: Project;
}

type State = {
    loading: boolean;
    error: string;
    newUserEmail: string;
    project: Project | null;
    users: User[];
}

export default class ShareProjectSheet extends BaseSheet<Props, State> {
    protected readonly type = "share-project";
    private emailInput: TextInput | null = null;

    public constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
            error: "",
            newUserEmail: "",
            project: null,
            users: [],
        };

        this.closeError = this.closeError.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.addUser = this.addUser.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.hideUser = this.hideUser.bind(this);
    }

    public async componentDidMount() {
        super.componentDidMount();

        const handleError = (error: any) => {
            this.setState({
                error: BaseDocument.getErrorMessage(error)
            });
        }

        await Project.get(this.props.project.identifier).then(async project => {
            await project.getEditors().then(editors => {
                this.setState({
                    loading: false,
                    project: project,
                    users: editors
                });
            }).catch(handleError);
        }).catch(handleError);
    }

    protected renderInner() {
        const submit = async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            await this.addUser();
        }

        return (
            <form onSubmit={submit}>
                {this.renderError()}

                <TextInput
                    type="email"
                    icon={ICONS.share}
                    validate={Validation.email}
                    onChange={this.updateUser}
                    placeholder={PLACEHOLDERS.email}
                    label={LABELS.shareByEmail}
                    className="add"
                    value={this.state.newUserEmail}
                    ref={input => this.emailInput = input}
                >
                    <div className="buttons">
                        <ProcessingButton onClick={this.addUser} className="primary small">
                            <SolidIcon name={ICONS.add}/>
                        </ProcessingButton>
                    </div>
                </TextInput>

                <div className="users">
                    {this.renderUsers()}
                </div>
            </form>
        );
    }

    protected renderButtons() {
        return (
            <button type="button" className="primary" onClick={this.close}>
                fertig
            </button>
        );
    }

    private renderUsers() {
        if (this.state.loading) {
            return <CircularProgressIndicator className="centered primary"/>;
        } else {
            return this.state.users.map(user => (
                <div key={`shared-user-${user.identifier}`}>
                    <div className="name">
                        <p className="name">{user.name}</p>
                    </div>

                    <ProcessingButton
                        onClick={() => this.removeUser(user.identifier)}
                        className="small"
                        onFinish={() => this.hideUser(user.identifier)}
                    >
                        <OutlinedIcon name={ICONS.remove}/>
                    </ProcessingButton>
                </div>
            ));
        }
    }

    private renderError() {
        if (this.state.error) {
            return <NegativePopup message={this.state.error} onClose={this.closeError}/>;
        }
    }

    private closeError() {
        this.setState({
            error: ""
        });
    }

    private async addUser() {
        const validation = Validation.email(this.state.newUserEmail);

        if (validation.valid) {
            if (this.state.project) {
                await this.state.project.addEditor(this.state.newUserEmail).then(user => {
                    this.setState({
                        newUserEmail: "",
                        users: [user, ...this.state.users]
                    });

                    this.emailInput?.reset();
                }).catch(error => {
                    this.setState({
                        error: BaseDocument.getErrorMessage(error)
                    });
                });
            }
        } else {
            this.setState({
                error: validation.message
            });
        }
    }

    private updateUser(email: string) {
        this.setState({
            newUserEmail: email
        });
    }

    private async removeUser(identifier: string) {
        if (this.state.project) {
            this.state.project.removeEditor(identifier).catch(error => {
                this.setState({
                    error: BaseDocument.getErrorMessage(error)
                });
            });
        }
    }

    private hideUser(identifier: string) {
        this.setState({
            users: this.state.users.filter(user => user.identifier !== identifier)
        });
    }
}