import React from "react";
import ReactDOM from "react-dom";
import BaseComponent, {BaseProps} from "../BaseComponent";
import {IconName} from "../../util/types";
import device from "../../util/device";
import OutlinedIcon from "../icons/OutlinedIcon";
import {DOUBLE_CLICK_DURATION, ICONS} from "../../util/constants";
import BaseDocument from "../../data/BaseDocument";

type Option = {
    action: () => void;
    icon: IconName;
    label: string;
    singleSelectionOnly: boolean;
}

export type BaseOverviewProps = BaseProps;

export type BaseOverviewState<ItemType extends BaseDocument> = {
    items: ItemType[];
    selected: string[];
    loading: boolean;
    showDeleteConfirmation: boolean;
}

export default abstract class BaseOverview<ItemType extends BaseDocument, Props extends BaseOverviewProps = BaseOverviewProps, State extends BaseOverviewState<ItemType> = BaseOverviewState<ItemType>> extends BaseComponent<Props, State> {
    protected abstract readonly options: Option[];
    private controlPressed = false;
    private shiftPressed = false;
    private blurOnClick = true;

    protected constructor(props: Props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
        this.handleHold = this.handleHold.bind(this);
        this.showDeleteSelected = this.showDeleteSelected.bind(this);
        this.hideDeleteSelected = this.hideDeleteSelected.bind(this);
        this.unselectAll = this.unselectAll.bind(this);
        this.preventBlur = this.preventBlur.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
    }

    public componentDidMount() {
        this.update();

        window.addEventListener("keydown", this.onKeyDown, false);
        window.addEventListener("keyup", this.onKeyUp, false);

        document.querySelectorAll("main, nav, footer").forEach(element => {
            element.addEventListener("click", this.onClick, false);
        });
    }

    public render() {
        return (
            <div id={this.id} className={this.classes("overview")}>
                {this.renderFloatingOptions()}
                {this.renderAdditionalSections()}

                <div className="header">
                    {this.renderHeader()}
                </div>

                <div className="content">
                    {this.state.loading || this.state.items.length > 0 ? this.renderContent() : this.renderEmpty()}
                </div>
            </div>
        );
    }

    protected update() {
        this.setState({
            loading: true
        }, async () => {
            this.setState({
                loading: false,
                items: await this.fetch()
            });
        });
    }

    protected abstract renderHeader(): React.ReactNode;

    protected abstract renderContent(): React.ReactNode;

    protected abstract renderEmpty(): React.ReactNode;

    protected abstract renderAdditionalSections(): React.ReactNode;

    protected abstract fetch(): Promise<ItemType[]>;

    protected abstract open(...identifiers: string[]): void;

    protected abstract async delete(): Promise<void>;

    protected abstract sortItems(): ItemType[];

    protected handleClick(identifier: string) {
        this.preventBlur();

        if (device.hasTouch()) {
            if (this.state.selected.length === 0) {
                this.open(identifier);
            } else {
                this.toggle(identifier);
            }
        } else {
            this.select(identifier);
        }
    }

    protected handleDoubleClick(identifier: string) {
        this.preventBlur();
        this.open(identifier);
    }

    protected handleHold(identifier: string) {
        this.select(identifier);
    }

    protected isSelected(identifier: string) {
        return this.state.selected.includes(identifier);
    }

    protected preventBlur() {
        this.blurOnClick = false;
    }

    protected showDeleteSelected() {
        this.setState({
            showDeleteConfirmation: true
        });
    }

    protected hideDeleteSelected() {
        this.setState({
            showDeleteConfirmation: false,
            selected: []
        });
    }

    private renderFloatingOptions() {
        if (this.state.selected.length > 0) {
            return ReactDOM.createPortal((
                <div onClick={this.preventBlur} className="floating-actions">
                    {this.renderOptions()}
                </div>
            ), document.body);
        }
    }

    private renderOptions() {
        const cancelOption = {
            action: this.unselectAll,
            icon: ICONS.close,
            label: "abbrechen",
            singleSelectionOnly: false
        };

        return [...this.options, cancelOption].map(option => {
            if (this.state.selected.length === 1 || !option.singleSelectionOnly) {
                return (
                    <button key={option.label.replace(" ", "-")} onClick={option.action}>
                        <OutlinedIcon name={option.icon}/>
                        <span>{option.label}</span>
                    </button>
                );
            } else {
                return null;
            }
        });
    }

    private select(identifier: string) {
        let selected = [identifier];

        if (this.shiftPressed) {
            let selectedIdentifiers = this.state.selected.map(selectedIdentifier => {
                return this.state.items.findIndex(item => item.identifier === selectedIdentifier);
            });

            let min = Math.min(...selectedIdentifiers);

            let max = this.state.items.findIndex(item => item.identifier === identifier);

            if (min > max) {
                min = max + 1;
                max = Math.max(...selectedIdentifiers) + 1;
            }

            for (let i = min; i < max; i++) {
                selected.push(this.state.items[i].identifier);
            }
        } else if (this.controlPressed) {
            if (this.isSelected(identifier)) {
                return this.toggle(identifier);
            } else {
                selected = [identifier, ...this.state.selected];
            }
        } else {
            if (this.isSelected(identifier) && this.state.selected.length === 1) {
                selected = [];
            }
        }

        this.setState({
            selected: selected
        });
    }

    private toggle(identifier: string) {
        let selected: string[];

        if (this.isSelected(identifier)) {
            selected = this.state.selected.filter(selectedIdentifier => selectedIdentifier !== identifier);
        } else {
            selected = [identifier, ...this.state.selected];
        }

        this.setState({
            selected: selected
        });
    }

    private unselectAll() {
        this.setState({
            selected: []
        });
    }

    private onClick() {
        setTimeout(() => {
            if (this.blurOnClick) {
                this.unselectAll();
            }

            this.blurOnClick = true;
        }, DOUBLE_CLICK_DURATION + 10);
    }

    private onKeyDown(event: KeyboardEvent) {
        if (event.key === "Control") {
            this.controlPressed = true;
        }

        if (event.key === "Shift") {
            this.shiftPressed = true;
        }
    }

    private onKeyUp(event: KeyboardEvent) {
        setTimeout(() => {
            if (event.key === "Control") {
                this.controlPressed = false;
            }

            if (event.key === "Shift") {
                this.shiftPressed = false;
            }
        }, DOUBLE_CLICK_DURATION);
    }
}