import React from "react";
import CheckBox from "../toggleables/CheckBox";
import {BaseSelectorProps} from "./BaseSelector";
import MultipleChoiceSelector from "./MultipleChoiceSelector";

export default class CheckBoxSelector extends MultipleChoiceSelector {
    protected readonly type = "check-box";

    public constructor(props: BaseSelectorProps<string[]>) {
        super(props);

        this.state = {
            selected: this.props.selected || []
        };
    }

    protected renderInner() {
        return this.props.options.map(option => <CheckBox
            key={`${this.id}-${option.identifier}`}
            toggled={this.isSelected(option.identifier)}
            label={option.label}
            onChange={selected => this.onChange(option.identifier, selected)}
            disabled={option.disabled}
            onEdit={this.onEdit(option.identifier)}
        />)
    }
}