import React from "react";
import TextInput from "../inputs/TextInput";
import Validation from "../../util/validation";
import PasswordInput from "../inputs/PasswordInput";
import ProcessingButton from "../buttons/ProcessingButton";
import {ICONS, LABELS, PLACEHOLDERS} from "../../util/constants";
import BaseForm, {BaseFormProps, BaseFormState} from "./BaseForm";
import User from "../../data/User";
import BaseDocument, {auth} from "../../data/BaseDocument";
import ReAuthenticationSheet from "../sheets/ReAuthenticationSheet";
import SignatureImageInput from "../inputs/SignatureImageInput";

type Data = {
    name: string;
    email: string;
    password: string;
}

type State = BaseFormState<Data> & {
    user: User | null;
    signature: string | null;
    showCookieConfiguration: boolean;
    showDeleteProfileConfirmation: boolean;
    showUpdateProfileConfirmation: boolean;
}

export default class SettingsForm extends BaseForm<Data, State> {
    public constructor(props: BaseFormProps) {
        super(props);

        this.state = {
            loading: true,
            message: "",
            processing: false,
            error: "",
            user: null,
            signature: null,
            data: {
                name: "",
                email: "",
                password: ""
            },
            showCookieConfiguration: false,
            showDeleteProfileConfirmation: false,
            showUpdateProfileConfirmation: false
        };

        this.signOut = this.signOut.bind(this);
        this.deleteProfile = this.deleteProfile.bind(this);
        this.showDeleteProfileConfirmation = this.showDeleteProfileConfirmation.bind(this);
        this.hideDeleteProfileConfirmation = this.hideDeleteProfileConfirmation.bind(this);
        this.showUpdateProfileConfirmation = this.showUpdateProfileConfirmation.bind(this);
        this.hideUpdateProfileConfirmation = this.hideUpdateProfileConfirmation.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
    }

    public componentDidMount() {
        auth.onAuthStateChanged(async () => {
            const user = await User.current();
            const signature = await user.getSignature().catch(() => null) || null;

            this.setState({
                loading: false,
                user: user,
                signature: signature,
                data: {
                    name: user.name,
                    email: user.email,
                    password: ""
                }
            });
        });
    }

    protected renderInputs() {
        const updateSignature = async (result: Promise<any>) => {
            this.startProcessing();

            await result.then(() => {
                this.setState({message: "Deine Unterschrift wurde aktualisiert."});
            }).catch(error => {
                this.setState({error: BaseDocument.getErrorMessage(error)});
            }).finally(() => {
                this.stopProcessing();
            });
        }

        const uploadSignature = async (imageData: string) => {
            if (this.state.user) {
                await updateSignature(this.state.user.addSignature(imageData));
            }
        }

        const removeSignature = async () => {
            if (this.state.user) {
                await updateSignature(this.state.user.removeSignature());
            }
        }

        return (
            <div>
                <section>
                    <TextInput
                        placeholder={PLACEHOLDERS.email}
                        icon={ICONS.email}
                        label={LABELS.email}
                        validate={Validation.email}
                        onChange={this.onEmailChange}
                        value={this.state.data.email}
                    />
                    <PasswordInput
                        placeholder={PLACEHOLDERS.password}
                        icon={ICONS.password}
                        label={LABELS.newPassword}
                        validate={Validation.newPassword}
                        onChange={this.onPasswordChange}
                        value={this.state.data.password}
                    />

                    <TextInput
                        placeholder={PLACEHOLDERS.name}
                        icon={ICONS.name}
                        label={LABELS.name}
                        validate={Validation.fullName}
                        onChange={this.onNameChange}
                        value={this.state.data.name}
                    />
                </section>

                <section>
                    <SignatureImageInput
                        image={this.state.signature || undefined}
                        onAdd={uploadSignature}
                        onRemove={removeSignature}
                    />
                </section>

                {this.renderDeleteProfileConfirmation()}
                {this.renderUpdateProfileConfirmation()}
            </div>
        );
    }

    protected renderButtons(): React.ReactNode | React.ReactNode[] {
        return [
            <ProcessingButton onClick={this.signOut} className="secondary" key="button-sign-out">
                abmelden
            </ProcessingButton>,
            <button onClick={this.showDeleteProfileConfirmation} className="dark" key="button-delete-profile">
                Profil löschen
            </button>
        ];
    }

    protected validate() {
        return [
            Validation.fullName(this.state.data.name),
            Validation.email(this.state.data.email),
            Validation.newPassword(this.state.data.password)
        ];
    }

    protected async submit() {
        const user = await User.current();

        const result = {
            error: false,
            message: "Deine Angaben wurden aktualisiert."
        };

        if (user.email === this.state.data.email && this.state.data.password.length === 0) {
            await user.update(this.state.data.name).catch(error => {
                result.error = true;
                result.message = BaseDocument.getErrorMessage(error);
            });
        } else {
            result.message = "";
            this.showUpdateProfileConfirmation();
        }

        return result;
    }

    private renderUpdateProfileConfirmation() {
        const fullUpdate = async () => {
            const handleError = (error: any) => {
                this.setState({
                    processing: false,
                    error: BaseDocument.getErrorMessage(error),
                    message: ""
                })
            }

            await User.current().then(async user => {
                await user.update(this.state.data.name, this.state.data.email, this.state.data.password).then(() => {
                    const data = this.state.data;
                    data.password = "";

                    this.setState({
                        processing: false,
                        error: "",
                        message: "Deine Angaben wurden aktualisiert."
                    });

                }).catch(handleError);
            }).catch(handleError);
        }

        if (this.state.showUpdateProfileConfirmation) {
            return <ReAuthenticationSheet
                title="Angaben aktualisieren"
                description="Bitte bestätige dein Passwort, um fortzufahren."
                onConfirm={fullUpdate}
                onClose={this.hideUpdateProfileConfirmation}
            />;
        }
    }

    private renderDeleteProfileConfirmation() {
        if (this.state.showDeleteProfileConfirmation) {
            return <ReAuthenticationSheet
                title="Profil löschen"
                description="Wenn du dein Profil löschst, werden dadurch auch alle deine Projekte gelöscht. Dieser Schritt kann nicht rückgängig gemacht werden. Bitte bestätige dein Passwort."
                onConfirm={this.deleteProfile}
                onClose={this.hideDeleteProfileConfirmation}
            />;
        }
    }

    private async signOut() {
        await User.signOut().catch(this.handleError);
    }

    private async deleteProfile() {
        const user = await User.current();
        await user.delete().catch(this.handleError);
    }

    private handleError(error: any) {
        this.setState({
            error: BaseDocument.getErrorMessage(error),
            processing: false
        });
    }

    private showUpdateProfileConfirmation() {
        this.setState({
            showUpdateProfileConfirmation: true
        });
    }

    private hideUpdateProfileConfirmation() {
        this.setState({
            showUpdateProfileConfirmation: false
        });
    }

    private showDeleteProfileConfirmation() {
        this.setState({
            showDeleteProfileConfirmation: true
        });
    }

    private hideDeleteProfileConfirmation() {
        this.setState({
            showDeleteProfileConfirmation: false
        });
    }

    private onNameChange(name: string) {
        const data = this.state.data;
        data.name = name;
        this.update(data);
    }

    private onEmailChange(email: string) {
        const data = this.state.data;
        data.email = email;
        this.update(data);
    }

    private onPasswordChange(password: string) {
        const data = this.state.data;
        data.password = password;
        this.update(data);
    }
}