import React from "react";
import BaseItemCard, {BaseItemCardProps} from "./BaseItemCard";
import SolidIcon from "../icons/SolidIcon";
import {ICONS} from "../../util/constants";
import Project from "../../data/Project";

type Props = BaseItemCardProps & {
    project: Project;
}

export default class ProjectItemCard extends BaseItemCard<Props> {
    protected readonly type = "project";

    protected renderInner() {
        return (
            <div className="header">
                <div className="icon-wrapper">
                    <SolidIcon name={this.props.project.isShared() ? ICONS.sharedProject : ICONS.ownedProject}/>
                </div>
                <div className="title-wrapper">
                    <h1>{this.props.project.title}</h1>
                </div>
            </div>
        );
    }
}