import React from "react";
import BaseInput, {BaseInputProps} from "./BaseInput";
import {InputTypes} from "../../util/types";
import {removeNonNumeric} from "../../util/helpers";

type Props = BaseInputProps<InputTypes.Date>;

export default class DateInput extends BaseInput<InputTypes.Date, Props> {
    protected readonly type = "date";
    protected readonly defaultValue = {day: null, month: null, year: null};
    private dayInput: HTMLInputElement | null = null;
    private monthInput: HTMLInputElement | null = null;
    private yearInput: HTMLInputElement | null = null;

    public constructor(props: Props) {
        super(props);

        this.state = {
            value: props.value || this.defaultValue,
            validation: this.initialValidation()
        };

        this.onChange = this.onChange.bind(this);
        this.onDayChange = this.onDayChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.onYearChange = this.onYearChange.bind(this);
        this.onDayBlur = this.onDayBlur.bind(this);
        this.onMonthBlur = this.onMonthBlur.bind(this);
        this.onYearBlur = this.onYearBlur.bind(this);
    }

    protected renderInner() {
        return (
            <div className="inputs">
                <input
                    id={this.inputId}
                    placeholder="31"
                    type="text"
                    inputMode="numeric"
                    ref={input => this.dayInput = input}
                    value={this.state.value.day || ""}
                    onChange={this.onDayChange}
                    onBlur={this.onDayBlur}
                />

                <input
                    type="text"
                    placeholder="01"
                    inputMode="numeric"
                    ref={input => this.monthInput = input}
                    value={this.state.value.month || ""}
                    onChange={this.onMonthChange}
                    onBlur={this.onMonthBlur}
                />

                <input
                    type="text"
                    placeholder={new Date().getFullYear().toString()}
                    inputMode="numeric"
                    ref={input => this.yearInput = input}
                    value={this.state.value.year || ""}
                    onChange={this.onYearChange}
                    onBlur={this.onYearBlur}
                />
            </div>
        );
    }

    protected onChange(event: React.ChangeEvent<HTMLInputElement>) {
        return removeNonNumeric(event.target.value);
    }

    private updateValue(key: keyof InputTypes.Date, value: string | null, max: number, min?: number, delayValidation: boolean = true) {
        if (value?.length === 0) {
            value = null;
        } else if (value?.length === 2 && min && parseInt(value) < min) {
            value = min.toString();
            if (value.length === 1) {
                value = "0" + value;
            }
        }

        const date = this.state.value;
        date[key] = value !== null ? parseInt(value) <= max ? value : max.toString() : null;
        this.update(date, delayValidation);
    }

    private updateDay(day: string | null, delayValidation: boolean = true) {
        return this.updateValue("day", day, 31, 1, delayValidation);
    }

    private updateMonth(month: string | null, delayValidation: boolean = true) {
        return this.updateValue("month", month, 12, 1, delayValidation);
    }

    private updateYear(year: string | null, delayValidation: boolean = true) {
        return this.updateValue("year", year, new Date().getFullYear(), 0, delayValidation);
    }

    private onDayChange(event: React.ChangeEvent<HTMLInputElement>) {
        let day = this.onChange(event);

        if (day && day.length === 1 && parseInt(day) > 3) {
            day = "0" + day;
        }

        if (day.length === 2 && this.monthInput) {
            this.monthInput.focus();
        }

        this.updateDay(day);
    }

    private onMonthChange(event: React.ChangeEvent<HTMLInputElement>) {
        let month = this.onChange(event);

        if (month && month.length === 1 && parseInt(month) > 1) {
            month = "0" + month;
        }

        if (month.length === 2 && this.yearInput) {
            this.yearInput.focus();
        }

        this.updateMonth(month);
    }

    private onYearChange(event: React.ChangeEvent<HTMLInputElement>) {
        const year = this.onChange(event);
        this.updateYear(year);
    }

    private onDayBlur() {
        let day = this.state.value.day;
        if (day && day.length < 2) {
            day = '0' + day;
        }

        this.updateDay(day);
    }

    private onMonthBlur() {
        let month = this.state.value.month;
        if (month && month.length < 2) {
            month = '0' + month;
        }

        this.updateMonth(month);
    }

    private onYearBlur() {
        let year = this.state.value.year;

        if (year && year.length < 4) {
            year = (new Date().getFullYear() - 10).toString().slice(0, 4 - year.length) + year;
        }

        this.updateYear(year, false);
    }
}