import React from "react";
import ProjectOverview from "../components/overview/ProjectOverview";
import User from "../data/User";

export default function ProjectOverviewPage() {
    User.redirectSignedOut();

    return (
        <main>
            <ProjectOverview/>
        </main>
    );
}