import BaseSelector, {BaseSelectorProps, BaseSelectorState} from "./BaseSelector";

export default abstract class SingleChoiceSelector<Props extends BaseSelectorProps<string | null> = BaseSelectorProps<string | null>, State extends BaseSelectorState<string | null> = BaseSelectorState<string | null>> extends BaseSelector<string | null, Props, State> {
    protected onChange(identifier: string, selected: boolean) {
        if (!this.isDisabled(identifier)) {
            if (identifier === this.state.selected) {
                if (!selected) {
                    this.update(null);
                }
            } else {
                this.update(identifier);
            }
        }
    }

    protected isSelected(identifier: string) {
        return this.state.selected === identifier;
    }
}