import BaseSheet, {BaseSheetProps} from "./BaseSheet";
import React from "react";
import OutlinedIcon from "../icons/OutlinedIcon";
import {ICONS, LABELS, MIN_PROCESSING_TIME, PLACEHOLDERS, WEATHER} from "../../util/constants";
import ProcessingButton from "../buttons/ProcessingButton";
import {Company, DisplayableFotoData, InputTypes, WeatherIdentifier} from "../../util/types";
import DateInput from "../inputs/DateInput";
import Validation from "../../util/validation";
import TimeRangeInput from "../inputs/TimeRangeInput";
import DropdownSelector from "../selectors/DropdownSelector";
import IntegerInput from "../inputs/IntegerInput";
import TextFieldInput from "../inputs/TextFieldInput";
import CheckBoxSelector from "../selectors/CheckBoxSelector";
import CircularProgressIndicator from "../indicators/CircularProgressIndicator";
import TextInput from "../inputs/TextInput";
import ConfirmationSheet from "./ConfirmationSheet";
import {dateToDateInput, inputToDate, inputToNumber} from "../../util/parse";
import SolidIcon from "../icons/SolidIcon";
import NotedImageInput from "../inputs/NotedImageInput";
import Project from "../../data/Project";
import Entry from "../../data/Entry";
import BaseDocument from "../../data/BaseDocument";
import NegativePopup from "../popup/NegativePopup";

const COMPANY_LEVEL_TYPE = "job-level";
const AVERAGE_MONTH_TEMPERATURE = [3.3, 5.3, 5.3, 10.5, 11.9, 16.8, 17.7, 19.9, 14.8, 10.9, 5.2, 3.7];

type FormData = {
    newCompanyName: string;
    date: InputTypes.Date;
    timeRange: InputTypes.TimeRange;
    temperature: InputTypes.Number;
    weather: string | null;
    note: string;
    companies: Company[];
    newFotos: InputTypes.Image[];
    existingFotos: DisplayableFotoData[];
}

export type CreateEntrySheetProps = BaseSheetProps & {
    project: Project;
    onFinish: (entry: Entry) => void;
    lastEntry: Entry | null;
}

type State = {
    title: string;
    level: number;
    valid: boolean;
    project: Project;
    loading: boolean;
    showCancelConfirmation: boolean;
    data: FormData;
    levels: Level[];
    error: string;
}

type Level = {
    identifier: string;
    renderer: () => React.ReactNode;
    validator?: () => boolean[];
    title?: string;
    type?: string;
}

export default class CreateEntrySheet<Props extends CreateEntrySheetProps = CreateEntrySheetProps> extends BaseSheet<Props, State> {
    protected readonly type = "create-entry";
    private changesByUser = false;
    private newCompanyNameInput: TextInput | null = null;

    public constructor(props: Props) {
        super(props);

        this.state = {
            title: "Erstellen",
            level: 0,
            project: props.project,
            loading: true,
            valid: false,
            showCancelConfirmation: false,
            error: "",
            data: {
                newCompanyName: "",
                date: {year: null, day: null, month: null},
                timeRange: {start: {hour: null, minute: null}, stop: {hour: null, minute: null}},
                temperature: null,
                weather: null,
                note: "",
                companies: [],
                newFotos: [],
                existingFotos: []
            },
            levels: [
                {
                    identifier: "level-1",
                    renderer: this.renderLevel1.bind(this),
                    validator: this.validateLevel1.bind(this)
                }, {
                    identifier: "level-2",
                    renderer: this.renderLevel2.bind(this),
                    validator: this.validateLevel2.bind(this),
                    title: "Wetter"
                }, {
                    identifier: "level-3",
                    renderer: this.renderLevel3.bind(this),
                    title: "Firmen"
                }, {
                    identifier: "level-4",
                    renderer: this.renderLevel4.bind(this),
                    title: "Notiz"
                }, {
                    identifier: "level-5",
                    renderer: this.renderLevel5.bind(this),
                    title: "Fotos"
                }
            ]
        };

        this.submit = this.submit.bind(this);
        this.cancel = this.cancel.bind(this);
        this.toggleCompanies = this.toggleCompanies.bind(this);
        this.addCompany = this.addCompany.bind(this);
        this.editCompanyName = this.editCompanyName.bind(this);
        this.back = this.back.bind(this);
        this.next = this.next.bind(this);
        this.hideCancelConfirmation = this.hideCancelConfirmation.bind(this);
        this.handleError = this.handleError.bind(this);
        this.hideErrorMessage = this.hideErrorMessage.bind(this);
    }

    public async componentDidMount() {
        super.componentDidMount();
        await this.fill();
        this.validate();
    }

    protected async fill() {
        const data = this.state.data;
        const now = new Date();
        const averageTemperature = AVERAGE_MONTH_TEMPERATURE[now.getMonth()];
        data.date = dateToDateInput(now);

        if (this.props.lastEntry) {
            data.temperature = Math.round((this.props.lastEntry.temperature + averageTemperature) / 2);
            data.companies = this.props.lastEntry.companies;

            if (now.getTime() - this.props.lastEntry.end.getTime() <= 2 * 24 * 60 * 60 * 1000) {
                data.weather = this.props.lastEntry.weather;
            }
        } else {
            data.temperature = AVERAGE_MONTH_TEMPERATURE[now.getMonth()];
        }

        this.updateCompanies(data.companies);
        this.setState({
            data: data,
            loading: false
        });
    }

    protected renderInner() {
        if (this.state.loading) {
            return (
                <form>
                    <CircularProgressIndicator className="primary absolute-centered"/>
                </form>
            );
        } else if (this.state.showCancelConfirmation) {
            return this.renderCancelConfirmation();
        } else {
            return this.currentLevel().renderer();
        }
    }

    protected renderButtons() {
        return (
            <div>
                <button
                    className={"round transparent"}
                    onClick={this.back}
                >
                    <OutlinedIcon name={this.state.level > 0 ? ICONS.back : ICONS.close}/>
                </button>

                {this.renderNextButton()}
                {this.renderError()}
            </div>
        );
    }

    protected getTitle() {
        return this.currentLevel().title || this.props.title;
    }

    protected async submit() {
        if (this.state.data.weather && this.state.data.temperature) {
            return Entry.create(
                this.props.project.identifier,
                inputToDate(this.state.data.date, this.state.data.timeRange.start),
                inputToDate(this.state.data.date, this.state.data.timeRange.stop),
                this.state.data.weather as WeatherIdentifier,
                inputToNumber(this.state.data.temperature),
                this.state.data.companies,
                this.state.data.newFotos,
                this.state.data.note
            ).catch(this.handleError);
        }
    }

    protected updateCompanies(companies: Company[]) {
        const data = this.state.data;
        const levels = this.state.levels.filter(level => level.type !== COMPANY_LEVEL_TYPE);

        data.companies = companies;
        const companyLevels: Level[] = [];
        companies.forEach(company => {
            companyLevels.push({
                title: company.name,
                renderer: () => this.renderCompany(company.identifier),
                identifier: company.identifier,
                type: COMPANY_LEVEL_TYPE
            });
        });

        this.setState({
            levels: [...levels.slice(0, 3), ...companyLevels, ...levels.slice(3, 5)],
            data: data
        });
    }

    protected handleError(error: any) {
        this.showErrorMessage(BaseDocument.getErrorMessage(error));
    }

    protected hideErrorMessage() {
        this.setState({
            error: ""
        })
    }

    private renderError() {
        if (this.state.error) {
            return <NegativePopup message={this.state.error} onClose={this.hideErrorMessage}/>
        }
    }

    private showErrorMessage(message: string) {
        this.setState({
            error: message
        });
    }

    private validate() {
        const level = this.currentLevel();

        if (level.validator) {
            this.setState({
                valid: level.validator().every(condition => condition)
            });
        }
    }

    private renderLevel1() {
        const onDateChange = (date: InputTypes.Date) => {
            const data = this.state.data;
            data.date = date;
            this.updateData(data);
        }

        const onTimeRangeChange = (timeRange: InputTypes.TimeRange) => {
            const data = this.state.data;
            data.timeRange = timeRange;
            this.updateData(data);
        }

        return (
            <form>
                <DateInput
                    validate={Validation.entryDate}
                    onChange={onDateChange}
                    value={this.state.data.date}
                    label={LABELS.date}
                    icon={ICONS.date}
                />

                <TimeRangeInput
                    validate={Validation.timeRange}
                    onChange={onTimeRangeChange}
                    value={this.state.data.timeRange}
                    label={LABELS.duration}
                />
            </form>
        );
    }

    private validateLevel1() {
        return [
            Validation.entryDate(this.state.data.date).valid,
            Validation.timeRange(this.state.data.timeRange).valid
        ];
    }

    private renderLevel2() {
        const onTemperatureChange = (temperature: InputTypes.Number) => {
            const data = this.state.data;
            data.temperature = temperature;
            this.updateData(data);
        }

        const onWeatherChange = (weather: string | null) => {
            const data = this.state.data;
            data.weather = weather;
            this.updateData(data);
        }

        return (
            <form>
                <IntegerInput
                    validate={Validation.temperature}
                    onChange={onTemperatureChange}
                    value={this.state.data.temperature}
                    label={LABELS.temperature}
                    icon={ICONS.temperature}
                    min={-50}
                    max={50}
                    placeholder={PLACEHOLDERS.temperature}
                />

                <DropdownSelector
                    options={Object.entries(WEATHER).map(element => ({identifier: element[0], label: element[1]}))}
                    onChange={onWeatherChange}
                    placeholder={PLACEHOLDERS.weather}
                    selected={this.state.data.weather}
                />
            </form>
        );
    }

    private validateLevel2() {
        return [
            Validation.temperature(this.state.data.temperature).valid,
            Validation.weather(this.state.data.weather).valid
        ];
    }

    private renderLevel3() {
        const onNewCompanyNameChange = (name: string) => {
            const data = this.state.data;
            data.newCompanyName = name;
            this.updateData(data);
        }

        return (
            <form>
                <TextInput
                    icon={ICONS.company}
                    validate={Validation.companyName}
                    onChange={onNewCompanyNameChange}
                    placeholder={PLACEHOLDERS.newCompany}
                    label={LABELS.newCompany}
                    value={this.state.data.newCompanyName}
                    className="add"
                    ref={input => this.newCompanyNameInput = input}
                >
                    <div className="buttons">
                        <ProcessingButton onClick={this.addCompany} className="primary small">
                            <SolidIcon name={ICONS.add}/>
                        </ProcessingButton>
                    </div>
                </TextInput>

                <CheckBoxSelector
                    options={this.state.project.companies.map(company => ({
                        identifier: company.identifier,
                        label: company.name
                    }))}
                    selected={this.state.data.companies.map(company => company.identifier)}
                    onChange={this.toggleCompanies}
                    className="close"
                    onEdit={this.editCompanyName}
                />
            </form>
        );
    }

    private renderLevel4() {
        const onNoteChange = (note: string) => {
            const data = this.state.data;
            data.note = note;
            this.updateData(data);
        }

        return (
            <form>
                <TextFieldInput
                    value={this.state.data.note}
                    validate={Validation.empty}
                    onChange={onNoteChange}
                    label={LABELS.note}
                    icon={ICONS.note}
                    placeholder={PLACEHOLDERS.note}
                />
            </form>
        );
    }

    private renderLevel5() {
        const onNewFotoChange = (images: InputTypes.Image[]) => {
            const data = this.state.data;
            data.newFotos = images;
            this.updateData(data);
        }

        const onExistingFotoChange = (images: DisplayableFotoData[]) => {
            const data = this.state.data;
            data.existingFotos = images;
            this.updateData(data);
        }

        return (
            <form>
                <NotedImageInput
                    existingImages={this.state.data.existingFotos}
                    onChange={onNewFotoChange}
                    onExistingChange={onExistingFotoChange}
                />
            </form>
        );
    }

    private renderCompany(identifier: string) {
        const company = this.state.data.companies.find(company => company.identifier === identifier);

        if (company) {
            const onWorkersChange = (company: Company, workers: InputTypes.Number) => {
                try {
                    company.workers = inputToNumber(workers);
                } catch (e) {
                    company.workers = 1;
                }

                this.updateCompany(company);
            }

            const onNoteChange = (company: Company, note: string) => {
                company.note = note;
                this.updateCompany(company);
            }

            const onWorkPlaceChange = (company: Company, workPlace: string) => {
                company.workPlace = workPlace;
                this.updateCompany(company);
            }

            const onMailReferenceChange = (company: Company, mailReference: string) => {
                company.mailReference = mailReference;
                this.updateCompany(company);
            }

            if (company) {
                return (
                    <form key={`company-${identifier}`}>
                        <IntegerInput
                            value={company.workers}
                            validate={Validation.empty}
                            icon={ICONS.workers}
                            label={LABELS.workers}
                            placeholder={PLACEHOLDERS.workers}
                            onChange={workers => onWorkersChange(company, workers)}
                            min={1}
                        />
                        <TextFieldInput
                            value={company.note}
                            validate={Validation.empty}
                            onChange={note => onNoteChange(company, note)}
                            label={LABELS.note}
                            icon={ICONS.note}
                            placeholder={PLACEHOLDERS.workNote}
                        />
                        <TextInput
                            type="text"
                            value={company.workPlace}
                            validate={Validation.empty}
                            onChange={workPlace => onWorkPlaceChange(company, workPlace)}
                            placeholder={PLACEHOLDERS.workPlace}
                            label={LABELS.workPlace}
                            icon={ICONS.place}
                        />
                        <TextInput
                            type="text"
                            value={company.mailReference}
                            validate={Validation.empty}
                            onChange={mailReference => onMailReferenceChange(company, mailReference)}
                            placeholder={PLACEHOLDERS.mailReference}
                            label={LABELS.mailReference}
                            icon={ICONS.mailReference}
                        />
                    </form>
                );
            }
        }
    }

    private renderCancelConfirmation() {
        if (this.state.showCancelConfirmation) {
            return <ConfirmationSheet
                title="Änderungen verwerfen"
                onClose={this.hideCancelConfirmation}
                description="Bist du dir sicher, dass Du die Erstellung dieses Eintrag abbrechen möchtest?"
                onConfirm={async () => setTimeout(() => {
                    this.close();
                }, MIN_PROCESSING_TIME)}
            />;
        }
    }

    private renderNextButton() {
        if (this.state.loading) {
            return null;
        } else if (this.state.level < this.state.levels.length - 1) {
            return (
                <button
                    disabled={!this.state.valid}
                    className="primary"
                    onClick={this.next}
                >
                    weiter
                </button>
            );
        } else {
            return (
                <ProcessingButton
                    disabled={!this.state.valid}
                    className="primary"
                    onClick={this.submit}
                    onFinish={result => {
                        if (result) {
                            this.props.onFinish(result as Entry);
                        }

                        this.close();
                    }}
                >
                    fertig
                </ProcessingButton>
            );
        }
    }

    private cancel() {
        this.setState({
            showCancelConfirmation: true
        });
    }

    private currentLevel() {
        let index = this.state.level;

        if (index < 0) {
            index = 0;
        } else if (index >= this.state.levels.length) {
            index = this.state.levels.length - 1;
        }

        return this.state.levels[index];
    }

    private async addCompany() {
        const name = this.state.data.newCompanyName;
        const validation = Validation.companyName(name);

        if (validation.valid) {
            await this.state.project.addCompany(name).then(company => {
                this.props.project.companies.unshift(company);
                const data = this.state.data;
                data.newCompanyName = "";

                this.updateData(data);
                this.newCompanyNameInput?.reset();
            }).catch(this.handleError);
        } else {
            this.showErrorMessage(validation.message);
        }
    }

    private async editCompanyName(identifier: string, name: string) {
        const validation = Validation.companyName(name);

        if (validation.valid) {
            const project = this.state.project;
            await project.renameCompany(identifier, name);

            const data = this.state.data;
            data.companies.forEach(company => {
                if (company.identifier === identifier) {
                    company.name = name;
                }
            });

            const levels = this.state.levels;
            levels.forEach(level => {
                if (level.identifier === identifier) {
                    level.title = name;
                }
            });

            this.setState({
                project: project,
                data: data,
                levels: levels
            });
        } else {
            this.showErrorMessage(validation.message);
        }
    }

    private toggleCompanies(identifiers: string[]) {
        const companies = [];
        for (const identifier of identifiers) {
            const companyIdentity = this.props.project.companies.find(company => {
                return company.identifier === identifier;
            });

            if (companyIdentity) {
                let company = this.state.data.companies.find(company => company.identifier === identifier);
                if (!company) {
                    company = {
                        identifier: companyIdentity.identifier,
                        name: companyIdentity.name,
                        note: "",
                        mailReference: "",
                        workers: 1,
                        workPlace: ""
                    };
                }

                companies.push(company);
            }
        }

        this.updateCompanies(companies);
    }

    private updateData(data: FormData) {
        this.changesByUser = true;
        this.setState({
            data: data
        }, this.validate);
    }

    private updateCompany(company: Company) {
        const data = this.state.data;
        for (let i = 0; i < data.companies.length; i++) {
            if (data.companies[i].identifier === company.identifier) {
                data.companies[i] = company;
            }
        }

        this.updateData(data);
    }

    private next() {
        const next = this.state.level + 1;

        this.setState({
            level: next < this.state.levels.length ? next : this.state.levels.length - 1
        }, this.validate);
    }

    private back() {
        const last = this.state.level - 1;

        if (last >= 0) {
            this.setState({
                level: last
            }, this.validate);
        } else if (this.changesByUser) {
            this.cancel();
        } else {
            this.close();
        }
    }

    private hideCancelConfirmation() {
        this.setState({
            showCancelConfirmation: false
        });
    }
}