import React from "react";
import BaseComponent, {BaseProps} from "../BaseComponent";
import {DisplayableFotoData, InputTypes} from "../../util/types";
import {toBase64} from "../../util/helpers";
import Validation from "../../util/validation";
import TextInput from "./TextInput";
import {ICONS, PLACEHOLDERS} from "../../util/constants";
import OutlinedIcon from "../icons/OutlinedIcon";
import {generateId} from "../../util/generate";
import ImageInput from "./ImageInput";

type NotedImageProps = BaseProps & {
    note: string;
    src: string;
    onChange: (note: string) => void;
    onDelete: () => void;
}

type NotedImageState = {
    note: string;
}

class NotedImage extends BaseComponent<NotedImageProps, NotedImageState> {
    public constructor(props: NotedImageProps) {
        super(props);

        this.state = {
            note: props.note
        };
    }

    public render() {
        return (
            <div className="image">
                <img src={this.props.src} alt={this.state.note}/>

                <div className="options">
                    <TextInput
                        placeholder={PLACEHOLDERS.imageNote}
                        icon={ICONS.note}
                        validate={Validation.empty}
                        onChange={this.props.onChange}
                        value={this.state.note}
                    />

                    <button className="light icon" type="button" onClick={() => this.props.onDelete()}>
                        <OutlinedIcon name={ICONS.delete}/>
                    </button>
                </div>
            </div>
        );
    }
}

type NotedImageInputProps = BaseProps & {
    existingImages: DisplayableFotoData[];
    onChange: (images: InputTypes.Image[]) => void;
    onExistingChange?: (images: DisplayableFotoData[]) => void;
}

type NotedImageInputState = {
    newImages: InputTypes.Image[];
    existingImages: DisplayableFotoData[];
}

export default class NotedImageInput extends BaseComponent<NotedImageInputProps, NotedImageInputState> {
    public constructor(props: NotedImageInputProps) {
        super(props);

        this.state = {
            newImages: [],
            existingImages: props.existingImages || []
        };
    }

    public render() {
        const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files) {
                const images = this.state.newImages;
                const files = Array.from(event.target.files);

                for (const file of files) {
                    images.push({
                        identifier: generateId(),
                        content: await toBase64(file),
                        note: ""
                    });
                }

                this.updateNewImages(images);
            }
        }

        return (
            <div id={this.id} className={this.classes("input image")}>
                <ImageInput multiple onChange={onChange}>
                    Bild hinzufügen
                </ImageInput>
                {this.renderImages()}
            </div>
        );
    }

    private renderImages() {
        if (this.state.newImages) {
            return (
                <div className="images">
                    {this.renderNewImages()}
                    {this.renderExistingImages()}
                </div>
            );
        }
    }

    private renderNewImages() {
        const onDelete = (identifier: string) => {
            this.updateNewImages(this.state.newImages.filter(image => image.identifier !== identifier));
        }

        const onChange = (identifier: string, note: string) => {
            const images = this.state.newImages;
            const image = images.find(image => image.identifier === identifier);

            if (image) {
                image.note = note;
                this.updateNewImages(images);
            }
        }

        return this.state.newImages.map((data, index) => <NotedImage
            key={`image-${this.id}-${index}`}
            onChange={note => onChange(data.identifier, note)}
            onDelete={() => onDelete(data.identifier)}
            src={data.content}
            note={data.note}
        />)
    }

    private renderExistingImages() {
        const onDelete = (identifier: string) => {
            this.updateExistingImages(this.state.existingImages.filter(data => data.identifier !== identifier));
        }

        const onChange = (identifier: string, note: string) => {
            const images = this.state.existingImages;
            const image = images.find(image => image.identifier === identifier);

            if (image) {
                image.note = note;
                this.updateExistingImages(new Array(...images));
            }
        }

        return this.state.existingImages.map((data, index) => <NotedImage
            key={data.identifier}
            src={data.url}
            note={data.note}
            onDelete={() => onDelete(this.state.existingImages[index].identifier)}
            onChange={note => onChange(this.state.existingImages[index].identifier, note)}
        />);
    }

    private updateNewImages(images: InputTypes.Image[]) {
        this.props.onChange(images);

        this.setState({
            newImages: images
        });
    }

    private updateExistingImages(images: DisplayableFotoData[]) {
        if (this.props.onExistingChange) {
            this.props.onExistingChange(images);
        }

        this.setState({
            existingImages: images
        });
    }
}