import React from "react";
import {ICONS} from "../../util/constants";
import SolidIcon from "../icons/SolidIcon";
import {Placeholder} from "../../util/types";
import SingleChoiceSelector from "./SingleChoiceSelector";
import {BaseSelectorProps, BaseSelectorState} from "./BaseSelector";

type Props = BaseSelectorProps<string | null> & {
    placeholder: Placeholder;
}

type State = BaseSelectorState<string | null> & {
    opened: boolean;
}

export default class DropdownSelector extends SingleChoiceSelector<Props, State> {
    protected readonly type = "dropdown";

    public constructor(props: Props) {
        super(props);

        this.state = {
            selected: props.selected || null,
            opened: false
        };

        this.toggle = this.toggle.bind(this);
    }

    protected renderInner() {
        return (
            <div className={this.state.opened ? "opened" : ""}>
                <button className="toggle" onClick={this.toggle} type="button">
                    <div className="wrapper">
                        <span className="selected">{this.selected()}</span>
                        <SolidIcon name={this.state.opened ? ICONS.collapse : ICONS.more}/>
                    </div>
                </button>
                <div className="options">
                    {this.props.options.map(option => {
                        if (this.state.selected !== option.identifier) {
                            return (
                                <button
                                    key={`${this.id}-${option.identifier}`}
                                    onClick={() => this.select(option.identifier)}
                                    type="button"
                                >
                                    {option.label}
                                </button>
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
            </div>
        );
    }

    private toggle() {
        this.setState({
            opened: !this.state.opened
        });
    }

    private select(identifier: string) {
        this.update(identifier);
        this.toggle();
    }

    private selected() {
        if (this.state.selected) {
            for (const option of this.props.options) {
                if (option.identifier === this.state.selected) {
                    return option.label;
                }
            }
        }

        return this.props.placeholder;
    }
}