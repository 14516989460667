import React from "react";
import Export from "../components/export/Export";
import User from "../data/User";

export default function ExportOverviewPage() {
    User.redirectSignedOut();

    return (
        <main>
            <Export/>
        </main>
    );
}