import {TRANSITION_DURATION} from "./constants";

const MIN_TRANSITION_DELAY = 5;

type TransitionProperty = "opacity" | "transform";

type StyleDeclaration = {
    property: TransitionProperty;
    final: string;
    initial?: string;
}

export default class Transition {
    private readonly id: string;
    private readonly remove: boolean;
    private readonly declarations: StyleDeclaration[];

    public constructor(id: string, remove: boolean, ...declarations: StyleDeclaration[]) {
        this.id = id;
        this.remove = remove;
        this.declarations = declarations;

        this.execute = this.execute.bind(this);
        this.prepare();
    }

    private static applyStyle(element: HTMLElement, property: TransitionProperty, value: string) {
        element.style[property] = value;
    }

    public now() {
        this.after(MIN_TRANSITION_DELAY);
    }

    public after(delay: number) {
        setTimeout(this.execute, delay >= MIN_TRANSITION_DELAY ? delay : MIN_TRANSITION_DELAY);
    }

    private resetDeclarations(element: HTMLElement) {
        this.declarations.forEach(declaration => {
            Transition.applyStyle(element, declaration.property, "");
        });
    }

    private prepare() {
        const element = this.getElement();

        if (element) {
            this.declarations.forEach(declaration => {
                if (declaration.initial) {
                    Transition.applyStyle(element, declaration.property, declaration.initial)
                }
            });
        }
    }

    private execute() {
        const element = this.getElement();

        if (element) {
            this.declarations.forEach(declaration => {
                if (declaration.final) {
                    Transition.applyStyle(element, declaration.property, declaration.final)
                }
            });

            setTimeout(() => {
                if (this.remove) {
                    element.remove();
                } else {
                    this.resetDeclarations(element);
                }
            }, TRANSITION_DURATION);
        }
    }

    private getElement() {
        return document.getElementById(this.id);
    }
}