import {Route, WeatherIdentifier} from "./types";
import {WEATHER} from "./constants";
export const EXPORT_ENTRIES_KEY = "export-entries";

export function randomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min)) + min;
}

export function duplicateObjectProperty(objects: { [key in string]: any }[], key: string) {
    const seen = new Set();
    return objects.some(object => seen.size === seen.add(object[key]).size);
}

export function removeExceptFirst(pattern: string, value: string) {
    const parts = value.split(pattern);
    return parts.splice(0, 2).join(pattern) + parts.join("");
}

export function maxDayOfMonth(month: number, year: number) {
    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
        monthLength[1] = 29;
    }

    return monthLength[month - 1];
}

export function removeNonNumeric(value: string) {
    return value.replace(/[^0-9]/, "");
}

export function areEqual(...values: any[]) {
    const json = values.map(value => JSON.stringify(value));
    return json.every(value => value === json[0]);
}

export function freezeBackground() {
    document.body.style.overflowY = "hidden";
}

export function unfreezeBackground() {
    document.body.style.overflowY = "";
}

export function currentRoute() {
    return window.location.pathname;
}

export function redirect(route: Route) {
    if (currentRoute() !== route) {
        window.location.href = route;
    }
}

export function numberToFixedDigitString(num: number, digits: number) {
    let str = num.toString();

    if (str.length > digits) {
        return str.slice(0, digits);
    } else {
        while (str.length < digits) {
            str = "0" + str;
        }

        return str;
    }
}

export function fillRouteParameters(route: Route, ...parameters: string[]) {
    let i = 0;

    return route.replace(/:(\w+)/g, () => {
        if (i >= parameters.length) {
            return parameters[parameters.length - 1];
        } else {
            return parameters[i++];
        }
    });
}

export function getMonthLength(month: number, year?: number) {
    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (year !== undefined) {
        if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
            monthLength[1] = 29;
        }
    }

    return monthLength[month - 1];
}

export function formatTime(date: Date) {
    return `${numberToFixedDigitString(date.getHours(), 2)}:${numberToFixedDigitString(date.getMinutes(), 2)}`;
}

export function formatDate(date: Date, showWeekDay: boolean = true) {
    const days = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
    return `${showWeekDay ? `${days[date.getDay()]}, ` : ""}${numberToFixedDigitString(date.getDate(), 2)}.${numberToFixedDigitString(date.getMonth() + 1, 2)}.${date.getFullYear()}`;
}

export function formatMonth(date: Date) {
    const months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
    return `${months[date.getMonth()]} ${date.getFullYear()}`;
}

export function getUrlParameter(nth: number = 1) {
    const parts = window.location.pathname.split("/");
    return parts[parts.length - nth];
}

export function actionVibration() {
    if ("vibrate" in window.navigator) {
        window.navigator.vibrate([60, 0, 10]);
    }
}

export function toBase64(file: File) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString());
        reader.onerror = error => reject(error);
    });
}

export function datesAreEqual(date1: Date, date2: Date) {
    return date1.toISOString() === date2.toISOString();
}

export function areJSONEqual(literal1: any, literal2: any) {
    return JSON.stringify(literal1) === JSON.stringify(literal2);
}

export function setEntryExportConfiguration(projectIdentifier: string, entryIdentifiers: string[] | "all") {
    localStorage.setItem(EXPORT_ENTRIES_KEY, JSON.stringify({
        projectIdentifier: projectIdentifier,
        entryIdentifiers: entryIdentifiers
    }));
}

export function getEntryExportConfiguration() {
    const data = localStorage.getItem(EXPORT_ENTRIES_KEY);

    if (data) {
        const configuration = JSON.parse(data);
        if (configuration.projectIdentifier && configuration.entryIdentifiers) {
            return {
                projectIdentifier: configuration.projectIdentifier,
                entryIdentifiers: configuration.entryIdentifiers
            };
        }
    }

    return null;
}

export function formatWeather(identifier: WeatherIdentifier) {
    return WEATHER[identifier] || WEATHER.cloudy;
}

export function inProduction() {
    return process.env.NODE_ENV === "production";
}