const COOKIE_CONFIG_STORAGE_KEY = "cookie-configuration";

const COOKIE_IDENTIFIERS = {
    essential: "essential",
    statistics: "statistics",
    analysis: "analysis",
} as const;

class CookieType {
    public readonly name: string;
    public readonly identifier: string;
    public readonly required: boolean;

    public constructor(name: string, identifier: string, required = false, accepted = true) {
        this.name = name;
        this.identifier = identifier;
        this.required = required;
        this._accepted = accepted;
    }

    private _accepted: boolean;

    public get accepted() {
        return this._accepted;
    }

    public set accepted(accepted: boolean) {
        if (!this.required) {
            this._accepted = accepted;
        }
    }

    public data() {
        return {
            name: this.name,
            identifier: this.identifier,
            accepted: this._accepted
        };
    }
}

export class CookieConfiguration {
    public essential = new CookieType("essentiell", COOKIE_IDENTIFIERS.essential, true);
    public statistics = new CookieType("Statistik", COOKIE_IDENTIFIERS.statistics);
    public analysis = new CookieType("Analyse", COOKIE_IDENTIFIERS.analysis);

    public constructor() {
        CookieConfiguration.load().forEach(cookie => {
            this.update(cookie.identifier, cookie.accepted);
        });
    }

    public static isSet() {
        return localStorage.getItem(COOKIE_CONFIG_STORAGE_KEY) !== null;
    }

    public static reset() {
        localStorage.removeItem(COOKIE_CONFIG_STORAGE_KEY);
        return new CookieConfiguration();
    }

    private static load() {
        const json = localStorage.getItem(COOKIE_CONFIG_STORAGE_KEY);

        if (json) {
            const data = JSON.parse(json);
            if (Array.isArray(data)) {
                return data;
            } else {
                console.warn("saved invalid cookie configuration format");
                return [];
            }
        } else {
            return [];
        }
    }

    public accept(...acceptedIdentifiers: string[]) {
        this.all().forEach(cookie => {
            this.update(cookie.identifier, acceptedIdentifiers.includes(cookie.identifier));
        });
    }

    public all() {
        return [this.essential, this.statistics, this.analysis];
    }

    public required() {
        return this.all().filter(type => type.required)
    }

    public save() {
        const json = JSON.stringify(this.all().map(cookie => cookie.data()));
        localStorage.setItem(COOKIE_CONFIG_STORAGE_KEY, json);
    }

    private update(identifier: string, accepted: boolean) {
        switch (identifier) {
            case COOKIE_IDENTIFIERS.essential:
                this.essential.accepted = accepted;
                break;

            case COOKIE_IDENTIFIERS.statistics:
                this.statistics.accepted = accepted;
                break;

            case COOKIE_IDENTIFIERS.analysis:
                this.analysis.accepted = accepted;
                console.log(this.analysis);
                break;

            default:
                console.warn("tried to handle an unspecified cookie type");
        }
    }
}