import React from "react";
import {ICONS, MIN_VALIDATION_DELAY} from "../../util/constants";
import Validation from "../../util/validation";
import OutlinedIcon from "../icons/OutlinedIcon";
import {generateClassName, generateId} from "../../util/generate";
import BaseComponent, {BaseProps} from "../BaseComponent";
import {IconName, Label, Placeholder} from "../../util/types";
import {areEqual} from "../../util/helpers";

export type BaseInputProps<DataType> = BaseProps & {
    placeholder?: Placeholder;
    icon?: IconName;
    label?: Label;
    value?: DataType;
    validate: (value: DataType) => Validation;
    onChange: (value: DataType) => void;
}

export type BaseInputState<DataType> = {
    value: DataType;
    validation: Validation;
}

export default abstract class BaseInput<DataType, Props extends BaseInputProps<DataType> = BaseInputProps<DataType>, State extends BaseInputState<DataType> = BaseInputState<DataType>> extends BaseComponent<Props, State> {
    protected abstract readonly type: string;
    protected abstract readonly defaultValue: DataType;
    protected readonly inputId = generateId();
    private validationTimeout: NodeJS.Timeout | null = null;

    protected constructor(props: Props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    public componentWillUnmount() {
        if (this.validationTimeout) {
            clearTimeout(this.validationTimeout);
        }
    }

    public render() {
        return (
            <div id={this.id} className={this.classes("input", this.type)}>
                {this.renderIcon()}
                {this.renderInner()}
                {this.renderLabel()}
                {this.props.children}
            </div>
        );
    }

    public reset() {
        this.setState({
            value: this.defaultValue,
            validation: Validation.empty()
        });
    }

    protected abstract renderInner(): React.ReactNode;

    protected abstract onChange(...args: unknown[]): void;

    protected update(value: DataType, delayValidation: boolean = true) {
        this.props.onChange(value)

        this.setState({
            value: value,
            validation: Validation.empty()
        }, () => this.validate(delayValidation));
    }

    protected initialValidation() {
        if (this.props.value) {
            return this.props.validate(this.props.value as DataType);
        } else {
            return Validation.empty();
        }
    }

    private renderIcon() {
        if (this.props.icon !== undefined) {
            let name: IconName;
            let classes = ["centered"];

            if (this.state.validation.empty) {
                name = this.props.icon as IconName;
            } else if (this.state.validation.valid) {
                name = ICONS.valid;
                classes.push("valid");
            } else {
                name = ICONS.invalid;
                classes.push("invalid");
            }

            return <OutlinedIcon name={name} className={generateClassName(...classes)}/>
        }
    }

    private renderLabel() {
        if (this.state.validation.empty) {
            if (this.props.label) {
                return <label htmlFor={this.id}>{this.props.label}</label>;
            }
        } else {
            return (
                <label htmlFor={this.inputId} className={this.state.validation.valid ? "valid" : "invalid"}>
                    {this.state.validation.valid ? this.props.label : this.state.validation.message}
                </label>
            );
        }
    }

    private validate(delay: boolean) {
        const initialValue = this.state.value;

        this.validationTimeout = setTimeout(() => {
            if (areEqual(initialValue, this.state.value)) {
                this.setState({
                    validation: this.props.validate(this.state.value)
                });
            }
        }, delay ? MIN_VALIDATION_DELAY : 0);
    }
}