import React from "react";
import EntryOverview from "../components/overview/EntryOverview";
import User from "../data/User";

export default function EntryOverviewPage() {
    User.redirectSignedOut();

    return (
        <main>
            <EntryOverview/>
        </main>
    );
}