import React from "react";
import BaseComponent from "../BaseComponent";

const STROKE_PROPORTION = 0.08;

export default class CircularProgressIndicator extends BaseComponent {
    public render() {
        return (
            <div id={this.id} className={this.classes("indicator", "circular-progress")}>
                <svg
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                    strokeWidth={100 * STROKE_PROPORTION}
                >
                    <circle cx={50} cy={50} r={(100 - 100 * STROKE_PROPORTION) / 2}/>
                </svg>
            </div>
        );
    }
}