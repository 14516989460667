import React from "react";
import {IconName} from "../../util/types";
import {ICON_TYPES} from "../../util/constants";
import {generateClassName} from "../../util/generate";
import BaseComponent, {BaseProps} from "../BaseComponent";

type IconType = typeof ICON_TYPES[keyof typeof ICON_TYPES];

type Props = BaseProps & {
    name: IconName;
}

export default abstract class BaseIcon extends BaseComponent<Props> {
    protected abstract readonly type: IconType;

    public render() {
        return (
            <div
                id={this.id}
                className={this.classes("icon")}

            >
                <span className={generateClassName("material-icons", this.specifyType())}>
                    {this.props.name}
                </span>
            </div>
        );
    }

    private specifyType() {
        switch (this.type) {
            case ICON_TYPES.solid:
                return "material-icons-solid";

            case ICON_TYPES.outlined:
                return "material-icons-outlined";

            default:
                throw new Error(`Icon type "${this.type}" is not specified.`);
        }
    }
}