import React from "react";
import BaseComponent, {BaseProps} from "../BaseComponent";
import {DisplayableFotoData, EntryData, ExportFormat} from "../../util/types";
import {EXPORT_FORMATS, PLACEHOLDERS} from "../../util/constants";
import DropdownSelector from "../selectors/DropdownSelector";
import CircularProgressIndicator from "../indicators/CircularProgressIndicator";
import Project from "../../data/Project";
import Entry from "../../data/Entry";
import {
    formatDate,
    formatTime,
    formatWeather,
    getEntryExportConfiguration,
    setEntryExportConfiguration
} from "../../util/helpers";
import {generateClassName} from "../../util/generate";
import Empty from "../util/Empty";
import {auth} from "../../data/BaseDocument";
import User from "../../data/User";

type State = {
    format: ExportFormat;
    loading: boolean;
    entries: Entry[];
    project: Project | null;
    images: { [key in string]: DisplayableFotoData[] };
    user: User | null;
    signature: string | null;
}

export default class Export extends BaseComponent<BaseProps, State> {
    public constructor(props: BaseProps) {
        super(props);

        this.state = {
            format: EXPORT_FORMATS.default,
            loading: true,
            entries: [],
            project: null,
            images: {},
            user: null,
            signature: null
        };
    }

    private static renderEmpty() {
        return <Empty
            title="Keine Einträge"
            description="Es wurden keine Einträge zum Exportieren gefunden."
            action={{
                label: "zurück",
                onClick: () => window.history.back()
            }}
        />;
    }

    public componentDidMount() {
        this.fetch().catch(() => {
            window.history.back();
        });

        auth.onAuthStateChanged(async () => {
            const user = await User.current();
            const signature = await user.getSignature().catch(() => null);

            this.setState({
                user: user,
                signature: signature
            });
        });
    }

    public render() {
        if (this.state.loading) {
            return <CircularProgressIndicator className="primary centered"/>;
        } else {
            return (
                <div id={this.id} className={this.classes("export")}>
                    {this.renderHeader()}
                    {this.renderContent()}
                </div>
            );
        }
    }

    private async fetch() {
        const configuration = getEntryExportConfiguration();
        let entries: Entry[] = [];
        let project: Project | null = null;

        if (configuration) {
            project = await Project.get(configuration.projectIdentifier);

            if (configuration.entryIdentifiers === "all") {
                entries = await Entry.getAll(configuration.projectIdentifier);
            } else if (Array.isArray(configuration.entryIdentifiers)) {
                const identifiers: string[] = [];

                for (const identifier of configuration.entryIdentifiers) {
                    const entry = await Entry.get(identifier);
                    entries.push(entry);
                    identifiers.push(entry.identifier);
                }

                configuration.entryIdentifiers = identifiers;
                setEntryExportConfiguration(configuration.projectIdentifier, identifiers);
            }
        }

        entries = entries.sort((a, b) => b.start.getTime() - a.start.getTime())

        const images = this.state.images;
        for (const entry of entries) {
            images[entry.identifier] = await entry.getDisplayableFotoData();
        }

        if (project) {
            let start, stop = "";

            if (entries.length > 0) {
                start = formatDate(entries[0].start, false);
                stop = formatDate(entries[entries.length - 1].end, false);
            }

            let timeRange = start;
            if (entries.length > 1) {
                timeRange += `bis ${stop}`;
            }

            if (entries.length > 0) {
                document.title = `${project.title} ${timeRange}`.replace(/[^a-zA-Z\d\s:]/g, "-");
            }
        }

        this.setState({
            loading: false,
            entries: entries,
            project: project,
            images: images
        });
    }

    private renderHeader() {
        const onFormatChange = (selected: string | null) => {
            if (selected) {
                this.setState({
                    format: selected as ExportFormat
                }, () => console.log(this.state.format));
            }
        }

        if (this.state.entries.length > 0) {
            return (
                <div className="header">
                    <DropdownSelector
                        options={Object.values(EXPORT_FORMATS).map(format => ({
                            identifier: format,
                            label: format
                        }))}
                        onChange={onFormatChange}
                        selected={EXPORT_FORMATS.default}
                        placeholder={PLACEHOLDERS.chooseFormat}
                    />

                    <button className="primary" onClick={window.print}>drucken</button>
                </div>
            );
        }
    }

    private renderContent() {
        if (this.state.entries.length > 0) {
            return (
                <div className="content">
                    {this.state.entries.map(entry => (
                        <div key={`entry-${entry.identifier}`}>
                            {this.renderMainPage(entry)}
                        </div>
                    ))}
                </div>
            );
        } else {
            return Export.renderEmpty();
        }
    }

    private renderMainPage(entry: Entry) {
        switch (this.state.format) {
            case EXPORT_FORMATS.dortmund:
                return this.renderDortmund(entry);

            default:
                return this.renderDefault(entry);
        }
    }

    private renderAttachmentPage(entry: EntryData) {
        if (entry.identifier in this.state.images) {
            return (
                <div className="attachment">
                    {this.state.images[entry.identifier].map(data => (
                        <div className="image" key={`image-${entry.identifier}-${data.identifier}`}>
                            <img src={data.url} alt={data.note}/>
                            <p>{data.note}</p>
                        </div>
                    ))}
                </div>
            );
        }
    }

    private renderDefault(entry: EntryData) {
        if (this.state.project) {
            const date = entry.start;

            return (
                <div className="default">
                    <div className="row row-1">
                        <div className="col-1">
                            <img
                                src={require("../../assets/images/export/corporate-logo.jpg")}
                                alt="Logo der SUS Architekten"
                            />
                        </div>

                        <div className="col-2">
                            <div className="el-1">
                                <h1 className="bold">Bautagebuch</h1>
                            </div>
                            <div className="el-2">
                                <p>Bauvorhaben:</p>
                                <p>{this.state.project.title}</p>
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="el-1">
                                <p className="big">Tagesblatt-Nr. <br/> {`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`}
                                </p>
                            </div>

                            <div className="el-2">
                                <p className="big">Datum: {formatDate(entry.start, false)}</p>
                                <p className="big">Uhrzeit: {formatTime(entry.start)} - {formatTime(entry.end)}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row row-2">
                        <div className="col-1">
                            <p className="big">Firma/Gewerk</p>
                        </div>

                        <div className="col-2">
                            <p className="small">Anzahl Mitarbeiter</p>
                        </div>

                        <div className="col-3">
                            <p className="small">Ausführung in Geschoss / Quadrant</p>
                        </div>

                        <div className="col-4">
                            <p className="big">Besondere Angaben, falls erforderlich</p>
                            <p className="big">Ausgeführte Arbeiten / Leistungsstand</p>
                            <p className="small">
                                Beginn, Fertigstellung, Weisungen, Zusatzaufträge, Be-<br/>hinderungen, Verzug,
                                Bedenken,
                                Großgeräte, Abweichungen von der Ausführungsplanung, sonstiges
                            </p>
                        </div>

                        <div className="col-5">
                            <p className="small">Verweis auf Schriftverkehr o.ä. (Empfänger, Datum, etc.)</p>
                        </div>
                    </div>

                    {entry.companies.map((company, index) => (
                        <div
                            className={generateClassName("row row-3", index === 0 ? "no-top-border" : "")}
                            key={`company-${entry.identifier}-${company.identifier}`}
                        >
                            <div className="col-1">
                                <p>{(index + 1).toString()} {company.name}</p>
                            </div>

                            <div className="col-2">
                                <p>{company.workers.toString()}</p>
                            </div>

                            <div className="col-3">
                                <p>{company.workPlace || "-"}</p>
                            </div>

                            <div className="col-4">
                                <p>{company.note || "-"}</p>
                            </div>

                            <div className="col-5">
                                <p>{company.mailReference || "-"}</p>
                            </div>
                        </div>
                    ))}

                    <div className="row row-4">
                        <div className="col-1">
                            <p className="big">Anlagen: {entry.fotos.length > 0 ? "Fotos" : ""}</p>
                        </div>

                        <div className="col-2">
                            <p className="big">Witterung: {formatWeather(entry.weather)}</p>
                            <br/>
                            <p className="big">Temperatur: {entry.temperature}°C</p>
                        </div>
                    </div>

                    <div className="row row-5">
                        <div className="col-1">
                            <p className="big">Besondere Vorkommnisse (ggf. besonderes Blatt beifügen)</p>
                            <br/>
                            <p>{entry.note}</p>
                        </div>
                    </div>

                    <div className="row row-6">
                        <div className="col-1">
                            <p>Kenntnis genommen (bei Bedarf)</p>
                            <p>Projektleitung</p>
                            <p className="bottom">Name, Datum, Unterschrift</p>
                        </div>

                        <div className="col-2">
                            {/* <p>Geprüft</p>
                            <p>Sachbearbeiter/-in StA. 65</p>
                            <p className="bottom">Name, Datum, Unterschrift</p> */}
                        </div>

                        <div className="col-3">
                            <p>Aufgestellt</p>
                            <p>Schoeps & Schlüter Architekten</p>
                            {this.renderSignature()}
                            <p className="bottom">{this.renderName(date)}</p>
                        </div>
                    </div>

                    {this.renderAttachmentPage(entry)}
                </div>
            );
        }
    }

    private renderDortmund(entry: EntryData) {
        if (this.state.project) {
            const date = entry.start;

            return (
                <div className="dortmund">
                    <div className="row row-1">
                        <div className="col-1">
                            <div className="texts">
                                <p className="bold">Stadt Dortmund</p>
                                <p>Projektgruppe</p>
                                <p>Sanierung Rathaus</p>
                            </div>
                            <img
                                src={require("../../assets/images/export/flag-dortmund.png")}
                                alt="Wappen der Stadt Dortmund"
                            />
                        </div>

                        <div className="col-2">
                            <div className="el-1">
                                <h1 className="bold">Bautagebuch</h1>
                            </div>
                            <div className="el-2">
                                <p>Bauvorhaben:</p>
                                <p>{this.state.project.title}</p>
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="el-1">
                                <p className="big">Tagesblatt-Nr. <br/> {`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`}
                                </p>
                            </div>

                            <div className="el-2">
                                <p className="big">Datum: {formatDate(entry.start, false)}</p>
                                <p className="big">Uhrzeit: {formatTime(entry.start)} - {formatTime(entry.end)}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row row-2">
                        <div className="col-1">
                            <p className="big">Firma/Gewerk</p>
                        </div>

                        <div className="col-2">
                            <p className="small">Anzahl Mitarbeiter</p>
                        </div>

                        <div className="col-3">
                            <p className="small">Ausführung in Geschoss / Quadrant</p>
                        </div>

                        <div className="col-4">
                            <p className="big">Besondere Angaben, falls erforderlich</p>
                            <p className="big">Ausgeführte Arbeiten / Leistungsstand</p>
                            <p className="small">
                                Beginn, Fertigstellung, Weisungen, Zusatzaufträge, Be-<br/>hinderungen, Verzug,
                                Bedenken,
                                Großgeräte, Abweichungen von der Ausführungsplanung, sonstiges
                            </p>
                        </div>

                        <div className="col-5">
                            <p className="small">Verweis auf Schriftverkehr o.ä. (Empfänger, Datum, etc.)</p>
                        </div>
                    </div>

                    {entry.companies.map((company, index) => (
                        <div
                            className={generateClassName("row row-3", index === 0 ? "no-top-border" : "")}
                            key={`company-${entry.identifier}-${company.identifier}`}
                        >
                            <div className="col-1">
                                <p>{(index + 1).toString()} {company.name}</p>
                            </div>

                            <div className="col-2">
                                <p>{company.workers.toString()}</p>
                            </div>

                            <div className="col-3">
                                <p>{company.workPlace || "-"}</p>
                            </div>

                            <div className="col-4">
                                <p>{company.note || "-"}</p>
                            </div>

                            <div className="col-5">
                                <p>{company.mailReference || "-"}</p>
                            </div>
                        </div>
                    ))}

                    <div className="row row-4">
                        <div className="col-1">
                            <p className="big">Anlagen: {entry.fotos.length > 0 ? "Fotos" : ""}</p>
                        </div>

                        <div className="col-2">
                            <p className="big">Witterung: {formatWeather(entry.weather)}</p>
                            <br/>
                            <p className="big">Temperatur: {entry.temperature}°C</p>
                        </div>
                    </div>

                    <div className="row row-5">
                        <div className="col-1">
                            <p className="big">Besondere Vorkommnisse (ggf. besonderes Blatt beifügen)</p>
                            <br/>
                            <p>{entry.note}</p>
                        </div>
                    </div>

                    <div className="row row-6">
                        <div className="col-1">
                            <p>Kenntnis genommen (bei Bedarf)</p>
                            <p>Projektleitung</p>
                            <p className="bottom">Name, Datum, Unterschrift</p>
                        </div>

                        <div className="col-2">
                            <p>Geprüft</p>
                            <p>Sachbearbeiter/-in StA. 65</p>
                            <p className="bottom">Name, Datum, Unterschrift</p>
                        </div>

                        <div className="col-3">
                            <p>Aufgestellt</p>
                            <p>Schoeps & Schlüter Architekten</p>
                            {this.renderSignature()}
                            <p className="bottom">{this.renderName(date)}</p>
                        </div>
                    </div>

                    {this.renderAttachmentPage(entry)}
                </div>
            );
        }
    }

    private renderSignature() {
        if (this.state.signature && this.state.user) {
            return <img
                className="signature"
                src={this.state.signature}
                alt={`Unterschrift von ${this.state.user.name}`}
            />;
        }
    }

    private renderName(date: Date) {
        if (this.state.user && this.state.signature) {
            return `${this.state.user.name}, ${formatDate(date, false)}`;
        } else {
            return "Name, Datum, Unterschrift";
        }
    }
}