import BaseInput, {BaseInputProps} from "./BaseInput";
import {InputTypes} from "../../util/types";
import React from "react";
import {numberToFixedDigitString, removeNonNumeric} from "../../util/helpers";

type Props = BaseInputProps<InputTypes.Time> & {
    onFinish?: () => void;
}

export default class TimeInput extends BaseInput<InputTypes.Time, Props> {
    protected readonly type = "time";
    protected readonly defaultValue = {hour: null, minute: null};
    private minuteInput: HTMLInputElement | null = null;
    private hourInput: HTMLInputElement | null = null;

    public constructor(props: Props) {
        super(props);

        this.state = {
            value: props.value || this.defaultValue,
            validation: this.initialValidation()
        }
    }

    public focus() {
        this.hourInput?.focus();
    }

    protected renderInner() {
        return (
            <div className="inputs">
                <input
                    id={this.inputId}
                    placeholder="12"
                    type="text"
                    inputMode="numeric"
                    ref={input => this.hourInput = input}
                    value={this.state.value.hour || ""}
                    onChange={event => this.onChange("hour", event, 23, 3)}
                    onBlur={() => this.onBlur("hour")}
                />

                <input
                    type="text"
                    placeholder="30"
                    inputMode="numeric"
                    ref={input => this.minuteInput = input}
                    value={this.state.value.minute || ""}
                    onChange={event => this.onChange("minute", event, 59, 6)}
                    onBlur={() => this.onBlur("minute")}
                />
            </div>
        );
    }

    protected onChange(key: keyof InputTypes.Time, event: React.ChangeEvent<HTMLInputElement>, max: number, minSingleDigit: number) {
        const time = this.state.value;
        let value: string | null = removeNonNumeric(event.target.value).substring(0, 2);

        if (!value) {
            value = null;
        } else if (value.length === 1 && parseInt(value) >= minSingleDigit) {
            value = numberToFixedDigitString(parseInt(value), 2);
        }

        if (value?.length === 2) {
            if (key === "hour") {
                this.minuteInput?.focus();
            } else if (this.props.onFinish) {
                this.props.onFinish();
            }
        }

        time[key] = value !== null ? parseInt(value) <= max ? value : max.toString() : null;
        this.update(time);
    }

    private onBlur(key: keyof InputTypes.Time) {
        const time = this.state.value;
        let value = this.state.value[key];

        if (value && value.length < 2) {
            value = numberToFixedDigitString(parseInt(value), 2);
        }

        time[key] = value;
        this.update(time);
    }
}