import React from "react";
import BaseComponent, {BaseProps} from "../BaseComponent";
import OutlinedIcon from "../icons/OutlinedIcon";
import {ICONS} from "../../util/constants";

type Props = BaseProps & {
    title: string;
    text: string;
}

export default class HintCard extends BaseComponent<Props> {
    public componentDidMount() {
        this.appear().now();
    }

    public render() {
        return (
            <div id={this.id} className={this.classes("hint-card")}>
                <div className="header">
                    <h1>{this.props.title}</h1>
                    <OutlinedIcon name={ICONS.information}/>
                </div>

                <p>
                    {this.props.text}
                </p>
            </div>
        );
    }
}