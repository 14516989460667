import {InputTypes} from "./types";
import {numberToFixedDigitString} from "./helpers";

class ParsingError extends Error {
    constructor(argType: string, returnType: string) {
        super(`Parsing Error: ${argType} -> ${returnType}`);
    }
}

export function dateToDateInput(date: Date): InputTypes.Date {
    return {
        year: date.getFullYear().toString(),
        day: numberToFixedDigitString(date.getDate(), 2),
        month: numberToFixedDigitString(date.getMonth() + 1, 2)
    };
}

export function dateToTimeInput(date: Date): InputTypes.Time {
    return {
        minute: numberToFixedDigitString(date.getMinutes(), 2),
        hour: numberToFixedDigitString(date.getHours(), 2)
    };
}

export function datesToTimeRangeInput(date1: Date, date2: Date): InputTypes.TimeRange {
    return {
        start: dateToTimeInput(date1),
        stop: dateToTimeInput(date2)
    };
}

export function inputToDate(date: InputTypes.Date, time?: InputTypes.Time) {
    return new Date(
        parseInt(date.year!),
        parseInt(date.month!) - 1,
        parseInt(date.day!),
        time ? parseInt(time.hour!) : undefined,
        time ? parseInt(time.minute!) : undefined,
    );
}

export function inputToNumber(input: InputTypes.Number): number {
    if (input === null || input === "-") {
        throw new ParsingError("InputTypes.Number", "number");
    } else {
        return input;
    }
}