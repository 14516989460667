import React from "react";
import BaseComponent, {BaseProps} from "../BaseComponent";
import {generateKey} from "../../util/generate";

type Props = BaseProps & {
    multiple?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default class ImageInput extends BaseComponent<Props> {
    public render() {
        let imageInput: HTMLInputElement | null = null;
        const triggerImageInput = () => {
            imageInput?.click();
        }

        return (
            <div id={this.id} className={this.classes("input image")}>
                <input
                    key={generateKey()}
                    onChange={this.props.onChange}
                    type="file"
                    accept="image/x-png,image/jpeg"
                    multiple={this.props.multiple}
                    className="hidden"
                    ref={input => imageInput = input}
                />

                <button type="button" className="image light wide" onClick={triggerImageInput}>
                    {this.props.children}
                </button>
            </div>
        );
    }

}