import React from "react";
import BaseForm, {BaseFormProps} from "./BaseForm";
import {ICONS, LABELS, PLACEHOLDERS, ROUTES} from "../../util/constants";
import Validation from "../../util/validation";
import TextInput from "../inputs/TextInput";
import {redirect} from "../../util/helpers";
import User from "../../data/User";
import BaseDocument from "../../data/BaseDocument";

type Data = {
    email: string;
}

export default class ForgotPasswordForm extends BaseForm<Data> {
    public constructor(props: BaseFormProps) {
        super(props);

        this.state = {
            message: "",
            processing: false,
            error: "",
            data: {
                email: ""
            }
        };

        this.onEmailChange = this.onEmailChange.bind(this);
    }

    protected renderInputs() {
        return (
            <div>
                <TextInput
                    type="email"
                    placeholder={PLACEHOLDERS.email}
                    icon={ICONS.email}
                    label={LABELS.email}
                    validate={Validation.email}
                    onChange={this.onEmailChange}
                    value={this.state.data.email}
                />
            </div>
        );
    }

    protected renderButtons() {
        return (
            <button onClick={() => redirect(ROUTES.signIn)} className="secondary">
                zurück zur Anmeldung
            </button>
        );
    }

    protected validate() {
        return [
            Validation.email(this.state.data.email)
        ];
    }

    protected async submit() {
        const result = {
            error: false,
            message: "Sieh in deinen E-Mails nach, um ein neues Passwort festzulegen."
        };

        await User.resetPassword(this.state.data.email).catch(error => {
            result.error = true;
            result.message = BaseDocument.getErrorMessage(error);
        });

        return result;
    }

    private onEmailChange(email: string) {
        const data = this.state.data;
        data.email = email;
        this.update(data);
    }
}