import BaseForm, {BaseFormProps} from "./BaseForm";
import React from "react";
import TextInput from "../inputs/TextInput";
import {ICONS, LABELS, PLACEHOLDERS, ROUTES} from "../../util/constants";
import Validation from "../../util/validation";
import Project from "../../data/Project";
import BaseDocument from "../../data/BaseDocument";
import {fillRouteParameters} from "../../util/helpers";

type Data = {
    title: string;
    builder: string;
}

export default class NewProjectForm extends BaseForm<Data> {
    public constructor(props: BaseFormProps) {
        super(props);

        this.state = {
            loading: false,
            message: "",
            processing: false,
            error: "",
            data: {
                title: "",
                builder: ""
            }
        };

        this.onTitleChange = this.onTitleChange.bind(this);
        this.onBuilderChange = this.onBuilderChange.bind(this);
    }

    protected renderInputs() {
        return (
            <div>
                <TextInput
                    placeholder={PLACEHOLDERS.projectTitle}
                    icon={ICONS.title}
                    label={LABELS.projectTitle}
                    validate={Validation.title}
                    onChange={this.onTitleChange}
                    value={this.state.data.title}
                />

                <TextInput
                    placeholder={PLACEHOLDERS.builder}
                    icon={ICONS.builder}
                    label={LABELS.builder}
                    validate={Validation.companyName}
                    onChange={this.onBuilderChange}
                    value={this.state.data.builder}
                />
            </div>
        );
    }

    protected renderButtons() {
        return (
            <button onClick={() => window.history.back()} className="secondary">
                abbrechen
            </button>
        );
    }

    protected validate() {
        return [
            Validation.title(this.state.data.title),
            Validation.companyName(this.state.data.builder)
        ];
    }

    protected async submit() {
        const result = {
            error: false,
            message: ""
        };

        await Project.create(this.state.data.title, this.state.data.builder).then(project => {
            window.location.href = fillRouteParameters(ROUTES.project, project.identifier);
        }).catch(error => {
            result.error = true;
            result.message = BaseDocument.getErrorMessage(error);
        });

        return result;
    }

    private onTitleChange(title: string) {
        const data = this.state.data;
        data.title = title;
        this.update(data);
    }

    private onBuilderChange(builder: string) {
        const data = this.state.data;
        data.builder = builder;
        this.update(data);
    }
}