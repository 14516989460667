import React from "react";
import Transition from "../util/transition";
import {generateClassName, generateId} from "../util/generate";

export type BaseProps = {
    className?: string;
}

export default abstract class BaseComponent<Props extends BaseProps = BaseProps, State = {}> extends React.Component<Props, State> {
    public readonly id = generateId();

    public abstract render(): React.ReactNode;

    protected classes(...classes: string[]) {
        return generateClassName(this.props.className, ...classes);
    }

    protected appear() {
        return new Transition(this.id, false, {
            property: "transform",
            final: "scale(1)",
            initial: "scale(0)"
        });
    }

    protected disappear() {
        return new Transition(this.id, true, {
            property: "transform",
            final: "scale(0)"
        });
    }

    protected fadeIn() {
        return new Transition(this.id, false, {
            property: "opacity",
            final: "1",
            initial: "0"
        });
    }

    protected fadeOut() {
        return new Transition(this.id, true, {
            property: "opacity",
            final: "0"
        });
    }

    protected slideInFromBottom() {
        return new Transition(this.id, false, {
            property: "transform",
            final: "translateY(0)",
            initial: "translateY(100%)"
        });
    }

    protected slideOutFromBottom() {
        return new Transition(this.id, true, {
            property: "transform",
            final: "translateY(100%)"
        });
    }
}