import BaseDocument, {firestore, storage} from "./BaseDocument";
import {Company, DisplayableFotoData, FotoData, InputTypes, WeatherIdentifier} from "../util/types";
import User from "./User";
import {STORAGE_PATHS} from "../util/constants";
import {generateInternalIdentifier} from "../util/generate";
import {areJSONEqual, datesAreEqual} from "../util/helpers";

const COLLECTION = "entries";

export default class Entry extends BaseDocument {
    public constructor(identifier: string, projectIdentifier: string, start: Date, end: Date, weather: WeatherIdentifier, temperature: number, companies: Company[], fotos: FotoData[], note: string) {
        super(COLLECTION, identifier);

        this._projectIdentifier = projectIdentifier;
        this._start = start;
        this._end = end;
        this._weather = weather;
        this._temperature = temperature;
        this._companies = companies;
        this._fotos = fotos;
        this._note = note;
    }

    private _projectIdentifier: string;

    public get projectIdentifier() {
        return this._projectIdentifier;
    }

    private _start: Date;

    public get start() {
        return this._start;
    }

    private _end: Date;

    public get end() {
        return this._end;
    }

    private _weather: WeatherIdentifier;

    public get weather() {
        return this._weather;
    }

    private _temperature: number;

    public get temperature() {
        return this._temperature;
    }

    private _companies: Company[];

    public get companies() {
        return this._companies;
    }

    private _fotos: FotoData[];

    public get fotos() {
        return this._fotos;
    }

    private _note: string;

    public get note() {
        return this._note;
    }

    public static async get(identifier: string) {
        const doc = await firestore.collection(COLLECTION).doc(identifier).get();
        const data = doc.data();

        if (doc.exists && data) {
            return new Entry(
                doc.id,
                data.projectIdentifier,
                data.start.toDate(),
                data.end.toDate(),
                data.weather,
                data.temperature,
                data.companies,
                data.fotos,
                data.note
            );
        } else {
            throw new Error(BaseDocument.ERRORS.inexistentEntry)
        }
    }

    public static async getAll(projectIdentifier: string) {
        const results = await firestore.collection(COLLECTION).where("projectIdentifier", "==", projectIdentifier).get();

        return results.docs.map(doc => {
            const data = doc.data();

            return new Entry(
                doc.id,
                data.projectIdentifier,
                data.start.toDate(),
                data.end.toDate(),
                data.weather,
                data.temperature,
                data.companies,
                data.fotos,
                data.note
            );
        });
    }

    public static async create(projectIdentifier: string, start: Date, end: Date, weather: WeatherIdentifier, temperature: number, companies: Company[], fotos: InputTypes.Image[], note: string) {
        const user = await User.current();
        note = note.trim();
        temperature = Math.round(temperature);

        if (user) {
            const doc = firestore.collection(COLLECTION).doc();
            const fotoData = await Entry.uploadFotos(fotos, projectIdentifier, doc.id);

            await doc.set({
                projectIdentifier: projectIdentifier,
                start: start,
                end: end,
                weather: weather,
                temperature: Math.round(temperature),
                companies: companies,
                fotos: fotoData,
                note: note
            });

            return new Entry(doc.id, projectIdentifier, start, end, weather, temperature, companies, fotos, note);
        } else {
            throw new Error(BaseDocument.ERRORS.noLogin)
        }
    }

    public static async uploadFotos(fotos: InputTypes.Image[], projectIdentifier: string, entryIdentifier: string) {
        const imageRef = Entry.getFotoReference(projectIdentifier, entryIdentifier);
        const fotoData: FotoData[] = [];

        for (const foto of fotos) {
            const identifier = generateInternalIdentifier();

            await imageRef.child(identifier).putString(foto.content, "data_url").then(() => {
                fotoData.push({
                    identifier: identifier,
                    note: foto.note
                });
            });
        }

        return fotoData;
    }

    public static async getCurrentProjectEntries(projectIdentifier: string, current: Date) {
        const min = current;
        min.setDate(1);
        min.setHours(1);
        min.setMinutes(1);
        min.setSeconds(1);

        const max = new Date(min.toISOString());
        max.setMonth(min.getMonth() + 1);
        if (max.getMonth() === 0) {
            max.setFullYear(max.getFullYear() + 1);
        }

        const entries: Entry[] = [];
        const results = await firestore.collection(COLLECTION).where("projectIdentifier", "==", projectIdentifier).where("start", ">=", min).where("start", "<=", max).get();

        results.docs.forEach(doc => {
            const data = doc.data();

            if (doc.exists && data) {
                const entry = new Entry(
                    doc.id,
                    data.projectIdentifier,
                    data.start.toDate(),
                    data.end.toDate(),
                    data.weather,
                    data.temperature,
                    data.companies,
                    data.fotos,
                    data.note
                );

                entries.push(entry);
            }
        });

        return entries;
    }

    public static async deleteAllFromProject(projectIdentifier: string) {
        const entries = await firestore.collection(COLLECTION).where("projectIdentifier", "==", projectIdentifier).get();
        entries.docs.forEach(doc => doc.ref.delete());
    }

    private static getFotoReference(projectIdentifier: string, entryIdentifier: string) {
        return storage.ref().child(`${STORAGE_PATHS.entryImages}/${projectIdentifier}/${entryIdentifier}`);
    }

    public async update(start: Date, end: Date, weather: WeatherIdentifier, temperature: number, companies: Company[], existingFotos: FotoData[], newFotos: InputTypes.Image[], note: string) {
        let data: any = {};
        note = note.trim();
        temperature = Math.round(temperature);

        if (!datesAreEqual(start, this._start)) {
            data.start = start;
            this._start = start;
        }

        if (!datesAreEqual(end, this._end)) {
            data.end = end;
            this._end = end;
        }

        if (weather !== this._weather) {
            data.weather = weather;
            this._weather = weather;
        }

        if (temperature !== this._temperature) {
            data.temperature = temperature;
            this._temperature = temperature;
        }

        if (!areJSONEqual(companies, this._companies)) {
            data.companies = companies;
            this._companies = companies;
        }

        if (note !== this._note) {
            data.note = note;
            this._note = note;
        }

        let fotosChanged = false;

        if (existingFotos.length !== this._fotos.length || existingFotos.some((foto, index) => this._fotos[index].note !== foto.note)) {
            fotosChanged = true;
            this._fotos = existingFotos.map(foto => ({
                identifier: foto.identifier,
                note: foto.note
            }));
        }

        if (newFotos) {
            fotosChanged = true;
            const fotoData = await Entry.uploadFotos(newFotos, this._projectIdentifier, this.identifier);
            this._fotos = [...fotoData, ...this._fotos];
        }

        if (fotosChanged) {
            data.fotos = this._fotos;
        }

        await this.reference.update(data);
    }

    public async delete() {
        await this.reference.delete();
    }

    public async getDisplayableFotoData() {
        const imageRef = Entry.getFotoReference(this._projectIdentifier, this.identifier);
        const data: DisplayableFotoData[] = [];

        for (const foto of this._fotos) {
            data.push({
                identifier: foto.identifier,
                note: foto.note,
                url: await imageRef.child(foto.identifier).getDownloadURL()
            });
        }

        return data;
    }
}