import {randomInteger} from "./helpers";
import {ID_LENGTH, IMAGE_IDENTIFIER_LENGTH, INTERNAL_IDENTIFIER_LENGTH, KEY_LENGTH} from "./constants";

const UNIQUE_VALUES = {
    id: new Set(),
    key: new Set()
} as const;

const CHARACTER_SETS = {
    alphabeticalUpper: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    alphabeticalLower: "abcdefghijklmnopqrstuvwxyz",
    numerical: "0123456789",
    special: "!?/,;.:-_*+§$%&|<>~"
} as const;

type CharacterSet = typeof CHARACTER_SETS[keyof typeof CHARACTER_SETS];

function generateString(length: number, ...alphabets: CharacterSet[]) {
    const alphabet = alphabets.join("");
    let result = "";

    for (let _ = 0; _ < length; _++) {
        const index = randomInteger(0, alphabet.length);
        result += alphabet[index];
    }

    return result;
}

export function generateInternalIdentifier() {
    return generateString(
        INTERNAL_IDENTIFIER_LENGTH,
        CHARACTER_SETS.alphabeticalUpper,
        CHARACTER_SETS.alphabeticalLower,
        CHARACTER_SETS.numerical
    );
}

export function generateImageIdentifier() {
    return generateString(
        IMAGE_IDENTIFIER_LENGTH,
        CHARACTER_SETS.alphabeticalUpper,
        CHARACTER_SETS.alphabeticalLower,
        CHARACTER_SETS.numerical
    );
}

export function generateId() {
    let id;
    do {
        id = generateString(ID_LENGTH, CHARACTER_SETS.alphabeticalUpper, CHARACTER_SETS.numerical);
    } while (UNIQUE_VALUES.id.has(id));

    UNIQUE_VALUES.id.add(id);
    return id;
}

export function generateKey() {
    let key;
    do {
        key = generateString(KEY_LENGTH, CHARACTER_SETS.alphabeticalUpper, CHARACTER_SETS.numerical, CHARACTER_SETS.special);
    } while (UNIQUE_VALUES.key.has(key));

    UNIQUE_VALUES.key.add(key);
    return key;
}

export function generateClassName(...classes: (string | undefined)[]) {
    let classNames: string[] = [];

    classes.forEach(className => {
        if (className) {
            classNames.push(className.trim());
        }
    });

    return classNames.join(' ');
}