import React from "react";
import NewProjectForm from "../components/forms/NewProjectForm";
import User from "../data/User";

export default function NewProject() {
    User.redirectSignedOut();

    return (
        <main>
            <NewProjectForm title="Neues Projekt" actionLabel="Projekt erstellen" className="major"/>
        </main>
    );
}