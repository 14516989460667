import React from "react";
import SignInForm from "../components/forms/SignInForm";
import User from "../data/User";

export default function SignInPage() {
    User.redirectSignedIn();

    return (
        <main>
            <SignInForm title="Anmelden" actionLabel="anmelden" className="major"/>
        </main>
    );
}