import CreateEntrySheet, {CreateEntrySheetProps} from "./CreateEntrySheet";
import {datesToTimeRangeInput, dateToDateInput, inputToDate, inputToNumber} from "../../util/parse";
import Entry from "../../data/Entry";
import {WeatherIdentifier} from "../../util/types";
import React from "react";
import ProcessingButton from "../buttons/ProcessingButton";

type Props = CreateEntrySheetProps & {
    entry: Entry;
}

export default class EditEntrySheet extends CreateEntrySheet<Props> {
    protected async fill() {
        this.updateCompanies(this.props.entry.companies);

        this.setState({
            loading: false,
            data: {
                newCompanyName: "",
                date: dateToDateInput(this.props.entry.start),
                timeRange: datesToTimeRangeInput(this.props.entry.start, this.props.entry.end),
                temperature: this.props.entry.temperature,
                weather: this.props.entry.weather,
                note: this.props.entry.note,
                companies: this.props.entry.companies,
                newFotos: [],
                existingFotos: await this.props.entry.getDisplayableFotoData()
            }
        });
    }

    protected async submit() {
        await this.props.entry.update(
            inputToDate(this.state.data.date, this.state.data.timeRange.start),
            inputToDate(this.state.data.date, this.state.data.timeRange.stop),
            this.state.data.weather as WeatherIdentifier,
            inputToNumber(this.state.data.temperature),
            this.state.data.companies,
            this.state.data.existingFotos,
            this.state.data.newFotos,
            this.state.data.note
        ).catch(this.handleError);

        return this.props.entry;
    }

    protected renderInner() {
        if (!this.state.loading && !this.state.showCancelConfirmation) {
            return (
                <div>
                    {super.renderInner()}
                    <ProcessingButton
                        className="small primary action"
                        onClick={this.submit}
                        onFinish={result => {
                            if (result) {
                                this.props.onFinish(result as Entry);
                            }

                            this.close();
                        }}
                    >
                        speichern
                    </ProcessingButton>
                </div>
            );
        } else {
            return super.renderInner();
        }
    }
}