import BaseOverview, {BaseOverviewProps, BaseOverviewState} from "./BaseOverview";
import React from "react";
import ProjectItemCard from "../item-cards/ProjectItemCard";
import {generateClassName, generateKey} from "../../util/generate";
import {ICONS, ROUTES} from "../../util/constants";
import {fillRouteParameters, redirect, setEntryExportConfiguration} from "../../util/helpers";
import ConfirmationSheet from "../sheets/ConfirmationSheet";
import ShareProjectSheet from "../sheets/ShareProjectSheet";
import Skeleton from "../util/Skeleton";
import Empty from "../util/Empty";
import device from "../../util/device";
import Project from "../../data/Project";
import {auth} from "../../data/BaseDocument";
import CircularProgressIndicator from "../indicators/CircularProgressIndicator";

const NUMBER_OF_PROJECTS_KEY = "number-of-projects";

type State = BaseOverviewState<Project> & {
    showShareOptions: boolean;
}

export default class ProjectOverview extends BaseOverview<Project, BaseOverviewProps, State> {
    protected readonly options = [
        {action: this.editSelected.bind(this), icon: ICONS.edit, label: "bearbeiten", singleSelectionOnly: true},
        {action: this.showDeleteSelected.bind(this), icon: ICONS.delete, label: "löschen", singleSelectionOnly: false},
        {action: this.showShareSelected.bind(this), icon: ICONS.share, label: "teilen", singleSelectionOnly: true},
        {action: this.exportAll.bind(this), icon: ICONS.export, label: "exportieren", singleSelectionOnly: true}
    ];

    public constructor(props: BaseOverviewProps) {
        super(props);

        this.state = {
            items: [],
            selected: [],
            loading: true,
            showDeleteConfirmation: false,
            showShareOptions: false
        };

        this.delete = this.delete.bind(this);
        this.openSelected = this.openSelected.bind(this);
        this.editSelected = this.editSelected.bind(this);
        this.showShareSelected = this.showShareSelected.bind(this);
        this.hideShareSelected = this.hideShareSelected.bind(this);
        this.exportAll = this.exportAll.bind(this);
    }

    protected async fetch() {
        return new Promise(resolve => {
            auth.onAuthStateChanged(async () => {
                const projects = await Project.getUserProjects();
                localStorage.setItem(NUMBER_OF_PROJECTS_KEY, projects.length.toString());
                resolve(projects);
            });
        }) as Promise<Project[]>;
    }

    protected renderHeader() {
        return (
            <div>
                <button
                    className={generateClassName("primary", device.isSmall() ? "small" : "")}
                    onClick={() => redirect(ROUTES.createProject)}
                >
                    Neues Projekt
                </button>
            </div>
        );
    }

    protected renderContent() {
        if (this.state.loading) {
            const numberOfProjects = parseInt(localStorage.getItem(NUMBER_OF_PROJECTS_KEY) || "0") || 0;
            const skeletons: React.ReactNode[] = [];

            for (let i = 0; i < numberOfProjects; i++) {
                skeletons.push(<Skeleton key={generateKey()} type="item-card project"/>);
            }

            return (
                <div className="wrapper">
                    {skeletons.length > 0 ? skeletons : <CircularProgressIndicator className="primary absolute-centered"/>}
                </div>
            );
        } else {
            return (
                <div className="wrapper">
                    {this.renderItems()}
                </div>
            );
        }
    }

    protected renderDeleteConfirmation() {
        if (this.state.showDeleteConfirmation) {
            return <ConfirmationSheet
                title={(this.state.selected.length > 1 ? "Projekte" : "Projekt") + " löschen"}
                onClose={this.hideDeleteSelected}
                description={`Bist du dir sicher, dass du ${this.state.selected.length > 1 ? "diese Projekte" : "dieses Projekt"} löschen möchtest? Dieser Schritt kann nicht rückgängig gemacht werden.`}
                onConfirm={this.delete}
            />;
        }
    }

    protected renderEmpty() {
        return <Empty
            title="Keine Projekte"
            description="Du hast noch keine Projekte. Klicke auf den Button, um ein Projekt zu erstellen."
            action={{
                label: "Projekt erstellen",
                onClick: () => redirect(ROUTES.createProject)
            }}
        />;
    }

    protected renderAdditionalSections() {
        return (
            <div>
                {this.renderShareProjectOption()}
                {this.renderDeleteConfirmation()}
            </div>
        );
    }

    protected sortItems() {
        return this.state.items.sort((a, b) => {
            const titleA = a.title.toUpperCase();
            const titleB = b.title.toUpperCase();
            return (titleA < titleB) ? -1 : (titleA > titleB) ? 1 : 0;
        });
    }

    protected open(identifier: string) {
        window.location.href = fillRouteParameters(ROUTES.project, identifier);
    }

    protected async delete() {
        this.state.selected.forEach(identifier => {
            const project = this.state.items.find(project => project.identifier === identifier);

            if (project) {
                project.delete();
            }
        });

        this.setState({
            items: this.state.items.filter(item => !this.state.selected.includes(item.identifier))
        });
    }

    private renderItems() {
        return this.sortItems().map(item => <ProjectItemCard
            key={item.identifier}
            identifier={item.identifier}
            selected={this.isSelected(item.identifier)}
            onClick={() => this.handleClick(item.identifier)}
            onDoubleClick={() => this.handleDoubleClick(item.identifier)}
            onHold={() => this.handleHold(item.identifier)}
            project={item}
        />);
    }

    private renderShareProjectOption() {
        if (this.state.showShareOptions && this.state.selected.length > 0) {
            const project = this.state.items.find(item => item.identifier === this.state.selected[0]);

            if (project) {
                return <ShareProjectSheet
                    project={project}
                    title={(this.state.selected.length > 1 ? "Projekte" : "Projekt") + " teilen"}
                    onClose={this.hideShareSelected}
                />;
            }
        }
    }

    private openSelected() {
        if (this.state.selected.length > 0) {
            this.open(this.state.selected[0]);
        }
    }

    private editSelected() {
        if (this.state.selected.length > 0) {
            window.location.href = fillRouteParameters(ROUTES.editProject, this.state.selected[0]);
        }
    }

    private showShareSelected() {
        this.setState({
            showShareOptions: true
        });
    }

    private hideShareSelected() {
        this.setState({
            showShareOptions: false
        });
    }

    private exportAll() {
        if (this.state.selected.length > 0) {
            setEntryExportConfiguration(this.state.selected[0], "all");
            redirect(ROUTES.export);
        }
    }
}