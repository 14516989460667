import React from "react";
import {IconName, InputTypes} from "../../util/types";
import BaseInput, {BaseInputProps} from "./BaseInput";
import OutlinedIcon from "../icons/OutlinedIcon";
import {ICONS} from "../../util/constants";
import {removeExceptFirst} from "../../util/helpers";

type Props = BaseInputProps<InputTypes.Number> & {
    min?: number;
    max?: number;
    steps?: number;
}

export default class IntegerInput extends BaseInput<InputTypes.Number, Props> {
    protected readonly type = "number";
    protected readonly defaultValue = null;

    public constructor(props: Props) {
        super(props);

        this.state = {
            value: props.value !== undefined ? props.value : this.defaultValue,
            validation: this.initialValidation()
        };

        this.decrease = this.decrease.bind(this);
        this.increase = this.increase.bind(this);
    }

    private static renderXcreaseButton(onClick: () => void, disabled: boolean, icon: IconName) {
        return (
            <button type="button" onClick={onClick} disabled={disabled} tabIndex={-1}>
                <OutlinedIcon name={icon}/>
            </button>
        );
    }

    protected renderInner() {
        return (
            <div>
                <input
                    id={this.inputId}
                    placeholder={this.props.placeholder}
                    type="text"
                    inputMode="numeric"
                    onChange={this.onChange}
                    value={this.format()}
                />

                <div className="buttons">
                    {this.renderDecreaseButton()}
                    {this.renderIncreaseButton()}
                </div>
            </div>
        );
    }

    protected onChange(event: React.ChangeEvent<HTMLInputElement>) {
        let value = event.target.value.replace(/[^0-9-]/, "");

        if (value[0] === "-" && (this.props.min === undefined || this.props.min < 0)) {
            value = removeExceptFirst("-", value);
        } else {
            value = value.replace("-", "");
        }

        value = value.slice(0, value[0] === "-" ? 9 : 8);

        if (value === "") {
            this.update(null);
        } else if (value === "-") {
            this.update("-")
        } else {
            this.update(parseInt(value));
        }
    }

    protected update(value: InputTypes.Number) {
        if (value !== null && value !== "-") {
            if (this.props.max !== undefined && value > this.props.max) {
                value = this.props.max;
            } else if (this.props.min !== undefined && value < this.props.min) {
                value = this.props.min;
            }
        }

        super.update(value);
    }

    private renderDecreaseButton() {
        return IntegerInput.renderXcreaseButton(
            this.decrease,
            this.state.value !== null && this.state.value !== "-" && this.props.min !== undefined && this.state.value <= this.props.min,
            ICONS.decrease
        );
    }

    private renderIncreaseButton() {
        return IntegerInput.renderXcreaseButton(
            this.increase,
            this.state.value !== null && this.state.value !== "-" && this.props.max !== undefined && this.state.value >= this.props.max,
            ICONS.increase
        );
    }

    private format() {
        switch (this.state.value) {
            case null:
                return "";
            case "-":
                return "-";
            default:
                return Math.round(this.state.value);
        }
    }

    private decrease() {
        if (this.state.value === null || this.state.value === "-") {
            this.update(0);
        } else {
            this.update(this.state.value - (this.props.steps || 1));
        }
    }

    private increase() {
        if (this.state.value === null || this.state.value === "-") {
            this.update(0);
        } else {
            this.update(this.state.value + (this.props.steps || 1));
        }
    }
}