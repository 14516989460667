import React from "react";
import BaseComponent, {BaseProps} from "../BaseComponent";
import ProcessingButton from "../buttons/ProcessingButton";
import PositivePopup from "../popup/PositivePopup";
import NegativePopup from "../popup/NegativePopup";
import CircularProgressIndicator from "../indicators/CircularProgressIndicator";
import Validation from "../../util/validation";

export type BaseFormProps = BaseProps & {
    title: string;
    actionLabel: string;
}

export type BaseFormState<FormData> = {
    loading?: boolean;
    processing: boolean;
    error: string;
    message: string;
    data: FormData;
}

type SubmitResult = {
    message: string;
    error: boolean;
}

export default abstract class BaseForm<FormData, State extends BaseFormState<FormData> = BaseFormState<FormData>> extends BaseComponent<BaseFormProps, State> {
    private lastProcessingTime: number = Date.now();

    protected constructor(props: BaseFormProps) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.closeError = this.closeError.bind(this);
    }

    public render() {
        if (!this.state.loading) {
            return (
                <form id={this.id} className={this.classes()} onSubmit={event => event.preventDefault()}>
                    <h1>{this.props.title}</h1>
                    {this.renderInputs()}

                    <div className="buttons">
                        <ProcessingButton
                            key={this.lastProcessingTime}
                            processing={this.state.processing}
                            onClick={this.onSubmit}
                            className="primary"
                            type="submit"
                        >
                            {this.props.actionLabel}
                        </ProcessingButton>

                        {this.renderButtons()}
                    </div>

                    {this.renderError()}
                    {this.renderMessage()}
                </form>
            );
        } else {
            return <CircularProgressIndicator className="primary centered"/>
        }
    }

    protected abstract renderInputs(): React.ReactNode;

    protected abstract renderButtons(): React.ReactNode;

    protected abstract validate(): Validation[];

    protected abstract async submit(): Promise<SubmitResult>;

    protected update(data: FormData) {
        this.setState({
            data: data
        });
    }

    protected startProcessing() {
        this.lastProcessingTime = Date.now();
        this.setState({processing: true});
    }

    protected stopProcessing() {
        this.lastProcessingTime = Date.now();
        this.setState({processing: false});
    }

    private renderMessage() {
        if (this.state.message) {
            return <PositivePopup onClose={this.closeMessage} message={this.state.message}/>;
        }
    }

    private renderError() {
        if (this.state.error) {
            return <NegativePopup onClose={this.closeError} message={this.state.error}/>;
        }
    }

    private async onSubmit() {
        this.setState({
            error: "",
            message: "",
            processing: true
        });

        for (const validation of this.validate()) {
            if (!validation.valid) {
                return this.setState({
                    processing: false,
                    error: validation.message
                });
            }
        }

        const result = await this.submit();

        this.setState({
            error: result.error ? result.message : "",
            message: result.error ? "" : result.message,
            processing: false
        });
    }

    private closeMessage() {
        this.setState({
            message: "",
        });
    }

    private closeError() {
        this.setState({
            error: "",
        });
    }
}