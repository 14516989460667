import React from "react";
import {OPERATOR} from "../util/constants";

export default function ImprintPage() {
    return (
        <main>
            <h1>Impressum</h1>

            <h2>Angaben gemäß §5 TMG</h2>
            <p>
                Betreiber: {OPERATOR.name}<br/>
                {OPERATOR.street} {OPERATOR.houseNumber}<br/>
                {OPERATOR.postCode} {OPERATOR.city}<br/>
                {OPERATOR.country}
            </p>

            <h2>Kontakt</h2>
            <p>
                E-Mail: {OPERATOR.email}<br/>
                Telefon: {OPERATOR.phoneNumber}
            </p>
        </main>
    );
}