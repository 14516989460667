import React from "react";
import ReactDOM from "react-dom";
import OutlinedIcon from "../icons/OutlinedIcon";
import BaseComponent, {BaseProps} from "../BaseComponent";
import {ICONS, MAX_POPUP_VISIBLE_TIME, TRANSITION_DURATION} from "../../util/constants";

type Props = BaseProps & {
    message: string;
    onClose: () => void;
}

export default abstract class BasePopup extends BaseComponent<Props> {
    protected abstract type: string;
    private visible = true;

    protected constructor(props: Props) {
        super(props);
        this.close = this.close.bind(this);
    }

    public componentDidMount() {
        this.appear().now();
        setTimeout(this.close, MAX_POPUP_VISIBLE_TIME);
    }

    public render() {
        return ReactDOM.createPortal((
            <div
                id={this.id}
                className={this.classes("popup", this.type)}
            >
                <div className="content">
                    <p>
                        {this.props.message}
                    </p>
                </div>

                <button className="round" onClick={this.close}>
                    <OutlinedIcon name={ICONS.close}/>
                </button>
            </div>
        ), document.body);
    }

    private close() {
        if (this.visible) {
            this.visible = false;
            this.disappear().now();
            setTimeout(this.props.onClose, TRANSITION_DURATION);
        }
    }
}