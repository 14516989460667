import React from "react";
import BaseComponent, {BaseProps} from "../BaseComponent";
import OutlinedIcon from "../icons/OutlinedIcon";
import {HOLD_ACTION_DURATION, ICONS} from "../../util/constants";
import {actionVibration, formatMonth} from "../../util/helpers";
import {generateClassName} from "../../util/generate";
import device from "../../util/device";

type Props = BaseProps & {
    value?: Date;
    onChange: (month: Date) => void;
}

type State = {
    current: Date;
}

export default class MonthSelector extends BaseComponent<Props, State> {
    private touchingPrevious: NodeJS.Timeout | null = null;
    private touchingNext: NodeJS.Timeout | null = null;

    public constructor(props: Props) {
        super(props);

        this.state = {
            current: props.value || new Date()
        };

        this.touchPrevious = this.touchPrevious.bind(this);
        this.touchNext = this.touchNext.bind(this);
        this.previous = this.previous.bind(this);
        this.fastPrevious = this.fastPrevious.bind(this);
        this.next = this.next.bind(this);
        this.fastNext = this.fastNext.bind(this);
    }

    public render() {
        const buttonClasses = generateClassName("icon transparent", device.isSmall() ? "small" : "");

        return (
            <div className={this.classes("month-selector")}>
                <button
                    className={buttonClasses}
                    onClick={this.previous}
                    onTouchStart={this.touchPrevious}
                    onMouseDown={this.touchPrevious}
                    onTouchEnd={() => this.touchingPrevious ? clearInterval(this.touchingPrevious) : null}
                    onMouseUp={() => this.touchingPrevious ? clearInterval(this.touchingPrevious) : null}
                >
                    <OutlinedIcon name={ICONS.back}/>
                </button>

                <button
                    className={buttonClasses}
                    onClick={this.next}
                    onTouchStart={this.touchNext}
                    onMouseDown={this.touchNext}
                    onTouchEnd={() => this.touchingNext ? clearInterval(this.touchingNext) : null}
                    onMouseUp={() => this.touchingNext ? clearInterval(this.touchingNext) : null}
                    disabled={!this.currentIsPast()}
                >
                    <OutlinedIcon name={ICONS.next}/>
                </button>

                <div className="month">
                    <span>{formatMonth(this.state.current)}</span>
                </div>
            </div>
        );
    }

    private update(month: Date) {
        const now = new Date();

        if (month.getFullYear() > now.getFullYear()) {
            month.setFullYear(now.getFullYear());
            month.setMonth(now.getMonth());
        }

        if (month.getMonth() > now.getMonth() && month.getFullYear() === now.getFullYear()) {
            month.setMonth(now.getMonth())
        }

        this.setState({
            current: month
        });

        this.props.onChange(month);
    }

    private currentIsPast() {
        return this.changeDateBy(1).getTime() <= Date.now();
    }

    private changeDateBy(months: number) {
        const current = new Date(this.state.current.toISOString());
        return new Date(current.setMonth(current.getMonth() + months))
    }

    private touchPrevious() {
        this.touchingPrevious = setInterval(() => {
            this.fastPrevious();
            actionVibration();
        }, HOLD_ACTION_DURATION)
    }

    private touchNext() {
        this.touchingNext = setInterval(() => {
            this.fastNext();
            actionVibration();
        }, HOLD_ACTION_DURATION)
    }

    private previous() {
        this.update(this.changeDateBy(-1));
    }

    private fastPrevious() {
        this.update(this.changeDateBy(-12));
    }

    private next() {
        this.update(this.changeDateBy(1));
    }

    private fastNext() {
        this.update(this.changeDateBy(12));
    }
}