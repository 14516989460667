import BaseDocument, {auth, firestore, storage} from "./BaseDocument";
import {redirect} from "../util/helpers";
import {ROUTES, STORAGE_PATHS} from "../util/constants";
import firebase from "firebase/app";
import {Route} from "../util/types";
import Project from "./Project";

const COLLECTION = "users";
const SIGNED_IN_KEY = "user-is-signed-in";
const USER_ID_KEY = "user-id-key";

auth.onAuthStateChanged(user => {
    localStorage.setItem(SIGNED_IN_KEY, String(user !== null));

    if (user) {
        localStorage.setItem(USER_ID_KEY, user.uid);
    } else {
        localStorage.removeItem(USER_ID_KEY);
    }
});

export default class User extends BaseDocument {
    public constructor(identifier: string, name: string, email: string) {
        super(COLLECTION, identifier);

        this._name = name;
        this._email = email;
    }

    private _name: string;

    public get name() {
        return this._name;
    }

    private _email: string;

    public get email() {
        return this._email;
    }

    public static async get(identifier: string) {
        const doc = await BaseDocument.getDocument(COLLECTION, identifier);
        const data = doc.data();

        if (doc.exists && data) {
            return new User(doc.id, data.name, data.email);
        } else {
            throw new Error(BaseDocument.ERRORS.inexistentUser);
        }
    }

    public static async getByEmail(email: string) {
        const result = await firestore.collection(COLLECTION).where("email", "==", email).get();
        const doc = result.docs[0];
        const data = doc?.data();

        if (doc?.exists && data) {
            return new User(doc.id, data.name, data.email);
        } else {
            throw new Error(BaseDocument.ERRORS.inexistentUser);
        }
    }

    public static async current() {
        if (auth.currentUser) {
            const doc = await firestore.collection(COLLECTION).doc(auth.currentUser.uid).get();
            const data = doc?.data();

            if (doc?.exists && data) {
                return new User(doc.id, data.name, data.email);
            }
        }

        throw new Error(BaseDocument.ERRORS.noLogin);
    }

    public static currentIdentifier() {
        return localStorage.getItem(USER_ID_KEY);
    }

    public static async reAuthenticate(password: string) {
        if (auth.currentUser) {
            const user = await User.current();

            if (user) {
                await auth.currentUser.reauthenticateWithCredential(
                    firebase.auth.EmailAuthProvider.credential(user.email, password)
                );
            } else {
                throw new Error(BaseDocument.ERRORS.unknown);
            }
        }
    }

    public static async signUp(name: string, email: string, password: string) {
        name = name.trim();
        email = email.trim();
        password = password.trim();

        await auth.createUserWithEmailAndPassword(email, password).then(async result => {
            if (result.user) {
                const doc = await firestore.collection(COLLECTION).doc(result.user.uid);

                doc.set({
                    name: name,
                    email: result.user.email
                }).then(() => {
                    redirect(ROUTES.projects);
                }).catch(error => {
                    result.user?.delete();
                    throw error;
                });
            } else {
                redirect(ROUTES.signIn)
                throw new Error(BaseDocument.ERRORS.unknown)
            }
        });
    }

    public static async signIn(email: string, password: string) {
        email.trim();
        password.trim();

        await auth.signInWithEmailAndPassword(email, password);
        redirect(ROUTES.projects);
    }

    public static async signOut() {
        await auth.signOut()
        await firestore.clearPersistence().finally(() => {
            redirect(ROUTES.signIn);
        });
    }

    public static async resetPassword(email: string) {
        await auth.sendPasswordResetEmail(email);
    }

    public static isSignedIn() {
        return localStorage.getItem(SIGNED_IN_KEY) === "true";
    }

    public static redirectSignedIn() {
        User.redirectOnAuthStatus(true, ROUTES.projects);
    }

    public static redirectSignedOut() {
        User.redirectOnAuthStatus(false, ROUTES.signIn);
    }

    public static async getSignatureReference(identifier: string) {
        return storage.ref().child(`${STORAGE_PATHS.signatureImages}/${identifier}`);
    }

    private static redirectOnAuthStatus(signedIn: boolean, target: Route) {
        if ((User.currentIdentifier() !== null) === signedIn) {
            redirect(target);
        }
    }

    public async delete() {
        if (auth.currentUser) {
            const projects = await Project.getUserProjects();

            for (const project of projects) {
                await project.delete();
            }

            await this.reference.delete();
            await auth.currentUser.delete();

            redirect(ROUTES.signUp);
        } else {
            throw new Error(BaseDocument.ERRORS.unknown);
        }
    }

    public async update(name: string, email?: string, password?: string) {
        if (auth.currentUser) {
            let data: any = {};

            if (name !== this._name) {
                this._name = name;
                data.name = name;
            }

            if (email && email !== this._email) {
                this._email = email;
                data.email = email;
                await auth.currentUser.updateEmail(email);
            }

            if (password && password.length > 0) {
                await auth.currentUser.updatePassword(password);
            }

            await this.reference.update(data);
        }
    }

    public async addSignature(image: string) {
        const ref = await User.getSignatureReference(this.identifier);
        return ref.putString(image, "data_url");
    }

    public async removeSignature() {
        const ref = await User.getSignatureReference(this.identifier);
        return ref.delete();
    }

    public async getSignature() {
        const ref = await User.getSignatureReference(this.identifier);
        return ref.getDownloadURL().catch(() => null);
    }
}