import NewProjectForm from "./NewProjectForm";
import {BaseFormProps} from "./BaseForm";
import {fillRouteParameters, getUrlParameter} from "../../util/helpers";
import Project from "../../data/Project";
import {ROUTES} from "../../util/constants";
import BaseDocument from "../../data/BaseDocument";

export default class EditProjectForm extends NewProjectForm {
    private project: Project | null = null;

    public constructor(props: BaseFormProps) {
        super(props);

        this.state = {
            loading: true,
            message: "",
            processing: false,
            error: "",
            data: {
                title: "",
                builder: ""
            }
        };
    }

    public async componentDidMount() {
        const identifier = getUrlParameter(1);
        await Project.get(identifier).then(project => {
            this.project = project;

            this.setState({
                loading: false,
                data: {
                    builder: project.builder,
                    title: project.title
                }
            });
        });
    }

    protected async submit() {
        const result = {
            error: false,
            message: ""
        };

        if (this.project) {
            await this.project.update(this.state.data.title, this.state.data.builder).then(() => {
                if (this.project) {
                    window.location.href = fillRouteParameters(ROUTES.project, this.project.identifier);
                }
            }).catch(error => {
                result.error = true;
                result.message = BaseDocument.getErrorMessage(error);
            });
        } else {
            result.error = true;
            result.message = BaseDocument.ERRORS.unknown;
        }

        return result;
    }
}