import React from "react";
import ReactDOM from "react-dom";
import Overlay from "../util/Overlay";
import BaseComponent, {BaseProps} from "../BaseComponent";
import {freezeBackground, unfreezeBackground} from "../../util/helpers";
import device from "../../util/device";
import {TRANSITION_DURATION} from "../../util/constants";

export type BaseSheetProps = BaseProps & {
    title: string;
    onClose: () => void;
}

export default abstract class BaseSheet<Props extends BaseSheetProps = BaseSheetProps, State = {}> extends BaseComponent<Props, State> {
    protected abstract readonly type: string;

    protected constructor(props: Props) {
        super(props);
        this.close = this.close.bind(this);
    }

    public componentDidMount() {
        freezeBackground();

        if (device.isSmall()) {
            this.slideInFromBottom().now();
        } else {
            this.fadeIn().now();
        }
    }

    public render() {
        return ReactDOM.createPortal((
            <div id={this.id} className={this.classes("sheet", this.type)}>
                <Overlay/>

                <div className="header">
                    <h1 className="decent">{this.getTitle()}</h1>
                </div>

                <div className="content">
                    {this.renderInner()}
                </div>

                <div className="buttons">
                    {this.renderButtons()}
                </div>
            </div>
        ), document.body);
    }

    protected abstract renderInner(): React.ReactNode;

    protected abstract renderButtons(): React.ReactNode;

    protected getTitle() {
        return this.props.title;
    }

    protected close() {
        unfreezeBackground();

        if (device.isSmall()) {
            this.slideOutFromBottom().now();
        } else {
            this.fadeOut().now();
        }

        setTimeout(this.props.onClose, TRANSITION_DURATION)
    }
}