import {MIN_PASSWORD_LENGTH, WEATHER} from "./constants";
import {InputTypes} from "./types";
import {getMonthLength} from "./helpers";

const MESSAGES = {
    pattern: {
        email: "Bitte gib eine echte E-Mail-Adresse an.",
        fullName: "Bitte gib deinen ganzen Namen an."
    },
    incomplete: {
        companyName: "Bitte gib einen Firmennamen an.",
        date: "Bitte gib ein ganzes Datum an.",
        name: "Bitte gib deinen Namen an.",
        password: `Dein Passwort muss mind. ${MIN_PASSWORD_LENGTH} Zeichen haben.`,
        time: "Bitte gib eine ganze Uhrzeit an.",
        title: "Bitte gib einen Titel an.",
        temperature: "Bitte gib eine Temperatur an."
    },
    invalid: {
        day: "Bitte gib einen sinnvollen Tag an.",
        month: "Bitte gib einen sinnvollen Monat an.",
        year: "Bitte gib ein sinnvolles Jahr an.",
        time: "Bitte gib eine sinnvolle Uhrzeit an.",
        weather: "Bitte wähle eine Witterung aus."
    },
    value: {
        futureDate: "Bitte gib ein aktuelles oder vergangenes Datum an.",
        acceptPrivacyConditions: "Um dich zu registrieren, musst Du die Datenschutzbedingungen akzeptieren."
    }
} as const;

class Constraint {
    public readonly met: boolean;
    public readonly message: string;

    public constructor(met: boolean, message: string) {
        this.met = met;
        this.message = met ? "" : message;
    }

    public static emailPattern(value: string) {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9öäüß]+\.)+[a-zA-Z]{2,}))$/;
        return Constraint.pattern(pattern, value, MESSAGES.pattern.email);
    }

    public static minLength(value: string, min: number, message: string) {
        return new Constraint(value.length >= min, message);
    }

    public static mustInclude(value: string, sequence: string, message: string) {
        return new Constraint(value.includes(sequence), message);
    }

    public static isDate(value: InputTypes.Date) {
        const [day, month, year] = [value.day, value.month, value.year].map(value => parseInt(value!));
        const monthLength = getMonthLength(month, year);

        if ([day, month, year].some(value => isNaN(value))) {
            return new Constraint(false, MESSAGES.incomplete.date);
        } else if (day < 0 || day > monthLength) {
            return new Constraint(false, MESSAGES.invalid.day);
        } else {
            return new Constraint(month >= 1 && month <= 12, MESSAGES.invalid.year);
        }
    }

    public static isPastOrPresentDate(value: InputTypes.Date) {
        const [day, month, year] = [value.day, value.month, value.year].map(value => parseInt(value!));
        const date = new Date(year, month - 1, day);
        const now = new Date();

        return new Constraint(now.getTime() >= date.getTime(), MESSAGES.value.futureDate)
    }

    public static isTime(value: InputTypes.Time) {
        const [hour, minute] = [value.hour, value.minute].map(value => parseInt(value!));

        if ([hour, minute].some(value => isNaN(value))) {
            return new Constraint(false, MESSAGES.incomplete.time);
        } else if (hour < 0 || hour > 24) {
            return new Constraint(false, MESSAGES.invalid.time);
        } else {
            return new Constraint(minute >= 0 && minute < 60, MESSAGES.invalid.time);
        }
    }

    public static isWeatherIdentifier(value: string | null) {
        return new Constraint(value ? value in WEATHER : false, MESSAGES.invalid.weather);
    }

    public static isNumber(value: InputTypes.Number, message: string) {
        return new Constraint(value !== "-" && value !== null, message);
    }

    public static isTrue(value: boolean, message: string) {
        return new Constraint(value, message);
    }

    private static pattern(pattern: RegExp, value: string, message: string) {
        return new Constraint(pattern.test(value), message);
    }
}

export default class Validation {
    public readonly empty: boolean;
    public readonly valid: boolean;
    public readonly message: string;

    private constructor(empty: boolean, constraints: Constraint[]) {
        this.empty = empty;
        this.valid = !empty;
        this.message = constraints[0]?.message || "";

        for (const constraint of constraints) {
            if (!constraint.met) {
                this.valid = false;
                this.message = constraint.message;
                break;
            }
        }
    }

    public static create(valid: boolean, message: string) {
        return new Validation(false, [new Constraint(valid, message)]);
    }

    public static empty() {
        return new Validation(true, []);
    }

    public static email(email: string) {
        return new Validation(email.length === 0, [
            Constraint.emailPattern(email.toLowerCase().trim())
        ]);
    }

    public static password(password: string) {
        return new Validation(password.length === 0, [
            Constraint.minLength(password, MIN_PASSWORD_LENGTH, MESSAGES.incomplete.password)
        ]);
    }

    public static newPassword(password: string) {
        if (password.length === 0) {
            return new Validation(false, []);
        } else {
            return Validation.password(password);
        }
    }

    public static fullName(name: string) {
        return new Validation(name.length === 0, [
            Constraint.minLength(name.trim(), 2, MESSAGES.incomplete.name),
            Constraint.mustInclude(name.trim(), " ", MESSAGES.pattern.fullName)
        ]);
    }

    public static entryDate(date: InputTypes.Date) {
        return new Validation(date.month === null && date.day === null && date.year === null, [
            Constraint.isDate(date),
            Constraint.isPastOrPresentDate(date)
        ]);
    }

    public static timeRange(timeRange: InputTypes.TimeRange) {
        return new Validation([timeRange.start.hour, timeRange.start.minute, timeRange.stop.hour, timeRange.stop.minute].every(value => value === null), [
            Constraint.isTime(timeRange.start),
            Constraint.isTime(timeRange.stop),
        ]);
    }

    public static weather(weather: string | null) {
        return new Validation(weather === null, [
            Constraint.isWeatherIdentifier(weather)
        ]);
    }

    public static temperature(temperature: InputTypes.Number) {
        return new Validation(temperature === null, [
            Constraint.isNumber(temperature, MESSAGES.incomplete.temperature)
        ]);
    }

    public static companyName(name: string) {
        return new Validation(name.length === 0, [
            Constraint.minLength(name.trim(), 1, MESSAGES.incomplete.companyName)
        ]);
    }

    public static acceptPrivacyConditions(accept: boolean) {
        return new Validation(false, [
            Constraint.isTrue(accept, MESSAGES.value.acceptPrivacyConditions)
        ]);
    }

    public static title(title: string) {
        return new Validation(title.length === 0, [
            Constraint.minLength(title.trim(), 1, MESSAGES.incomplete.title)
        ]);
    }
}