import React from "react";
import ReactDOM from "react-dom";
import Overlay from "../util/Overlay";
import {ICONS, TRANSITION_DURATION} from "../../util/constants";
import OutlinedIcon from "../icons/OutlinedIcon";
import BaseComponent, {BaseProps} from "../BaseComponent";
import {freezeBackground, unfreezeBackground} from "../../util/helpers";
import {generateClassName} from "../../util/generate";
import device from "../../util/device";

export type BaseDialogProps = BaseProps & {
    title: string;
    onClose?: () => void;
}

export default abstract class BaseDialog<Props extends BaseDialogProps = BaseDialogProps, State = {}> extends BaseComponent<Props, State> {
    protected abstract type: string;

    protected constructor(props: Props) {
        super(props);

        this.close = this.close.bind(this);
    }

    public componentDidMount() {
        freezeBackground();
        this.fadeIn().now();
    }

    public componentWillUnmount() {
        this.close();
    }

    public render() {
        return ReactDOM.createPortal((
            <dialog id={this.id} className={this.classes("dialog", this.type)}>
                <Overlay/>

                <div className="header">
                    <h1 className="decent">
                        {this.props.title}
                    </h1>

                    <OutlinedIcon name={ICONS.notification}/>
                </div>

                <div className="contents">
                    {this.renderInner()}
                </div>
            </dialog>
        ), document.body);
    }

    protected abstract renderInner(): React.ReactNode;

    protected renderCloseButton() {
        return (
            <button onClick={() => this.close()} className={generateClassName(device.isSmall() ? "round small" : "")}>
                {device.isSmall() ? <OutlinedIcon name={ICONS.close}/> : "abbrechen"}
            </button>
        );
    }

    protected close(delay = 0) {
        unfreezeBackground();

        delay = delay >= TRANSITION_DURATION ? delay : TRANSITION_DURATION;
        this.fadeOut().after(delay);

        setTimeout(() => {
            if (this.props.onClose) {
                this.props.onClose();
            }
        }, delay);
    }
}