import React from "react";
import BaseComponent, {BaseProps} from "../BaseComponent";
import {ROUTES} from "../../util/constants";
import CookieDialog from "../dialogs/CookieDialog";
import device from "../../util/device";

type State = {
    showCookieConfiguration: boolean;
}

export default class Footer extends BaseComponent<BaseProps, State> {
    public constructor(props: BaseProps) {
        super(props);

        this.state = {
            showCookieConfiguration: false
        };

        this.showCookieConfiguration = this.showCookieConfiguration.bind(this);
        this.hideCookieConfiguration = this.hideCookieConfiguration.bind(this);
    }

    public componentDidMount() {
        const footer = document.getElementById(this.id);
        if (footer && footer.offsetTop < window.innerHeight - footer.clientHeight) {
            footer.style.position = "absolute";

            if (device.isSmall()) {
                footer.style.bottom = (-1 * footer.clientHeight).toString() + "px";
            } else {
                footer.style.bottom = "0";
            }
        }
    }

    public render() {
        return (
            <footer id={this.id} className={this.classes()}>
                <a href={ROUTES.imprint}>Impressum</a>
                <a href={ROUTES.privacy}>Datenschutz</a>

                <button onClick={this.showCookieConfiguration}>
                    Cookie Einstellungen
                </button>

                {this.renderCookieConfiguration()}
            </footer>
        );
    }

    private renderCookieConfiguration() {
        if (this.state.showCookieConfiguration) {
            return <CookieDialog title={"Cookies"} onClose={this.hideCookieConfiguration}/>;
        }
    }

    private showCookieConfiguration() {
        this.setState({
            showCookieConfiguration: true
        });
    }

    private hideCookieConfiguration() {
        this.setState({
            showCookieConfiguration: false
        });
    }
}