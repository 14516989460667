import React from "react";
import BaseComponent, {BaseProps} from "../BaseComponent";
import OutlinedIcon from "../icons/OutlinedIcon";
import {ICONS} from "../../util/constants";
import ProcessingButton from "../buttons/ProcessingButton";

type Props = BaseProps & {
    label: string;
    labelElement?: React.ReactNode;
    toggled?: boolean;
    disabled?: boolean;
    onChange: (toggled: boolean) => void;
    onDisabledClick?: (toggled: boolean) => void;
    onEdit?: (label: string) => Promise<void>;
}

type State = {
    toggled: boolean;
    editing: boolean;
    label: string;
}

export default abstract class BaseToggleable extends BaseComponent<Props, State> {
    protected abstract readonly type: string;
    private inputElement: HTMLInputElement | null = null;

    protected constructor(props: Props) {
        super(props);

        this.state = {
            toggled: props.toggled || false,
            editing: false,
            label: props.label
        }

        this.enableEditing = this.enableEditing.bind(this);
        this.disableEditing = this.disableEditing.bind(this);
        this.toggle = this.toggle.bind(this);
        this.onEdit = this.onEdit.bind(this);
    }

    public render() {
        return (
            <div
                id={this.id}
                className={this.classes("toggleable", this.type, this.state.toggled ? "toggled" : "", this.props.disabled ? "disabled" : "")}

            >
                <button className="toggle" onClick={this.toggle} type="button">
                    {this.renderBox()}
                </button>

                {this.renderLabel()}
                {this.renderEditButton()}
            </div>
        );
    }

    protected abstract renderBox(): React.ReactNode | undefined;

    protected enableEditing() {
        this.setState({
            editing: true
        }, () => {
            if (this.inputElement) {
                this.inputElement.select();
            }
        });
    }

    protected disableEditing() {
        this.setState({
            editing: false,
            toggled: true
        });

        this.props.onChange(true);
    }

    private renderLabel() {
        if (this.state.editing) {
            return <input value={this.state.label} onChange={this.onEdit} ref={input => this.inputElement = input}/>;
        } else {
            return (
                <label htmlFor={this.id}>
                    {this.props.labelElement || this.state.label}
                </label>
            );
        }
    }

    private renderEditButton() {
        if (this.props.onEdit) {
            const className = "transparent round edit";

            if (this.state.editing) {
                return (
                    <ProcessingButton
                        onClick={() => this.props.onEdit!(this.state.label)}
                        onFinish={this.disableEditing}
                        className={className}
                        disabled={this.state.label.trim().length === 0}
                    >
                        <OutlinedIcon name={ICONS.save}/>
                    </ProcessingButton>
                )
            } else {
                return (
                    <button type="button" className={className} onClick={this.enableEditing}>
                        <OutlinedIcon name={ICONS.edit}/>
                    </button>
                );
            }
        }
    }

    private toggle() {
        if (!this.props.disabled) {
            const toggled = !this.state.toggled;

            this.setState({
                toggled: toggled
            });

            this.props.onChange(toggled);
        } else if (this.props.onDisabledClick) {
            this.props.onDisabledClick(this.state.toggled);
        }
    }

    private onEdit(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            label: event.target.value
        });
    }
}