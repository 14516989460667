import BaseInput, {BaseInputProps} from "./BaseInput";
import {InputTypes} from "../../util/types";
import React from "react";
import TimeInput from "./TimeInput";
import Validation from "../../util/validation";
import {ICONS} from "../../util/constants";

export default class TimeRangeInput extends BaseInput<InputTypes.TimeRange> {
    protected readonly type = "time-range";
    protected readonly defaultValue = {start: {minute: null, hour: null}, stop: {minute: null, hour: null}};
    private stopInput: TimeInput | null = null;

    public constructor(props: BaseInputProps<InputTypes.TimeRange>) {
        super(props);

        this.state = {
            value: props.value || this.defaultValue,
            validation: this.initialValidation()
        }
    }

    protected renderInner() {
        return (
            <div className="multiple">
                <TimeInput
                    validate={Validation.empty}
                    onChange={time => this.onChange(time, "start")}
                    onFinish={this.jumpFocus}
                    icon={ICONS.time}
                    value={this.state.value.start}
                />

                <TimeInput
                    validate={Validation.empty}
                    ref={input => this.stopInput = input}
                    onChange={time => this.onChange(time, "stop")}
                    icon={ICONS.time}
                    value={this.state.value.stop}
                />
            </div>
        );
    }

    protected onChange(time: InputTypes.Time, key: keyof InputTypes.TimeRange) {
        const timeRange = this.state.value;
        timeRange[key] = time;
        this.update(timeRange);
    }

    private jumpFocus() {
        this.stopInput?.focus();
    }
}