import React from "react";
import BaseComponent, {BaseProps} from "../BaseComponent";
import CircularProgressIndicator from "../indicators/CircularProgressIndicator";
import {MIN_PROCESSING_TIME} from "../../util/constants";

type Props = BaseProps & {
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
    processing?: boolean;
    onFinish?: (result: unknown) => void;
    onClick: () => Promise<unknown>;
}

type State = {
    processing: boolean;
}

export default class ProcessingButton extends BaseComponent<Props, State> {
    private notProcessingButton: HTMLButtonElement | null = null;

    public constructor(props: Props) {
        super(props);

        this.state = {
            processing: props.processing || false
        };

        this.process = this.process.bind(this);
        this.setNotProcessingButton = this.setNotProcessingButton.bind(this);
    }

    public render() {
        return (
            <button
                id={this.id}
                type={this.props.type || "button"}
                className={this.classes("processing", this.state.processing ? "active" : "")}
                onClick={this.process}
                disabled={this.state.processing || this.props.disabled}
                ref={this.setNotProcessingButton}
                style={{
                    width: this.state.processing ? this.notProcessingButton?.clientWidth + "px" : undefined
                }}
            >
                {this.renderInner()}
            </button>
        );
    }

    private renderInner() {
        if (this.state.processing) {
            return <CircularProgressIndicator/>;
        } else {
            return this.props.children;
        }
    }

    private process() {
        if (!this.state.processing) {
            const start = Date.now();
            this.setState({
                processing: true
            },async () => {
                const result = await this.props.onClick();

                setTimeout(() => {
                    this.setState({
                        processing: false
                    }, () => {
                        if (this.props.onFinish) {
                            this.props.onFinish(result);
                        }
                    });
                }, MIN_PROCESSING_TIME + start - Date.now());
            });
        }
    }

    private setNotProcessingButton(button: HTMLButtonElement | null) {
        if (!this.state.processing) {
            this.notProcessingButton = button;
        }
    }
}