import BaseDocument, {firestore} from "./BaseDocument";
import {CompanyIdentity} from "../util/types";
import User from "./User";
import {generateInternalIdentifier} from "../util/generate";
import Entry from "./Entry";

const COLLECTION = "projects";

export default class Project extends BaseDocument {
    private _ownerIdentifier: string;
    private _editorIdentifiers: string[];

    public constructor(identifier: string, title: string, builder: string, ownerIdentifier: string, editorIdentifiers: string[], companies: CompanyIdentity[]) {
        super(COLLECTION, identifier);

        this._title = title;
        this._builder = builder;
        this._ownerIdentifier = ownerIdentifier;
        this._editorIdentifiers = editorIdentifiers;
        this._companies = companies;
    }

    private _builder: string;

    public get builder() {
        return this._builder;
    }

    private _title: string;

    public get title() {
        return this._title;
    }

    private _companies: CompanyIdentity[];

    public get companies() {
        return this._companies;
    }

    public static async create(title: string, builder: string) {
        const user = await User.current();

        title = title.trim();
        builder = builder.trim();

        const ownerIdentifier = user.identifier;
        const editorIdentifiers = [ownerIdentifier];

        const doc = firestore.collection(COLLECTION).doc();
        await doc.set({
            title: title,
            builder: builder,
            ownerIdentifier: ownerIdentifier,
            editorIdentifiers: editorIdentifiers,
            companies: []
        });

        return new Project(doc.id, title, builder, ownerIdentifier, editorIdentifiers, []);
    }

    public static async getUserProjects() {
        const user = await User.current();

        const results = await firestore.collection(COLLECTION).where("editorIdentifiers", "array-contains", user.identifier).get();
        return results.docs.map(doc => {
            const data = doc.data();

            return new Project(
                doc.id,
                data.title,
                data.builder,
                data.ownerIdentifier,
                data.editorIdentifiers,
                data.companies
            );
        })
    }

    public static async get(identifier: string) {
        const doc = await BaseDocument.getDocument(COLLECTION, identifier);
        const data = doc.data();

        if (doc.exists && data) {
            return new Project(doc.id, data.title, data.builder, data.ownerIdentifier, data.editorIdentifiers, data.companies);
        } else {
            throw new Error(BaseDocument.ERRORS.inexistentProject);
        }
    }

    public async update(title: string, builder: string) {
        let data: any = {};

        if (title !== this._title) {
            this._title = title;
            data.title = title;
        }

        if (builder !== this._builder) {
            this._builder = builder;
            data.builder = builder;
        }

        if (Object.values(data).length > 0) {
            await this.reference.update(data);
        }
    }

    public async delete() {
        const userIdentifier = User.currentIdentifier();

        if (userIdentifier === this._ownerIdentifier) {
            this._editorIdentifiers = this._editorIdentifiers.filter(identifier => {
                return identifier !== this._ownerIdentifier
            });

            if (this._editorIdentifiers.length > 0) {
                this._ownerIdentifier = this._editorIdentifiers[this._editorIdentifiers.length - 1];

                await this.reference.update({
                    ownerIdentifier: this._ownerIdentifier,
                    editorIdentifiers: this._editorIdentifiers
                });
            } else {
                await Entry.deleteAllFromProject(this.identifier);
                await this.reference.delete();
            }
        } else {
            await this.reference.update({
                editorIdentifiers: this._editorIdentifiers.filter(identifier => {
                    return identifier !== userIdentifier;
                })
            });
        }
    }

    public async getEditors() {
        const editors: User[] = [];
        const currentIdentifier = User.currentIdentifier();

        for (let i = 0; i < this._editorIdentifiers.length; i++) {
            const identifier = this._editorIdentifiers[i];

            if (identifier !== currentIdentifier && identifier !== this._ownerIdentifier) {
                const editor = await User.get(identifier).catch(() => undefined);

                if (editor) {
                    editors.push(editor);
                }
            }
        }

        return editors;
    }

    public async addEditor(email: string) {
        const editor = await User.getByEmail(email);

        if (this._editorIdentifiers.every(identifier => identifier !== editor.identifier)) {
            this._editorIdentifiers.push(editor.identifier);

            await this.reference.update({
                editorIdentifiers: this._editorIdentifiers
            });

            return editor;
        } else {
            throw Error(BaseDocument.ERRORS.userAlreadyEditor);
        }
    }

    public async removeEditor(identifier: string) {
        const currentIdentifier = User.currentIdentifier();

        if (identifier !== currentIdentifier && identifier !== this._ownerIdentifier) {
            this._editorIdentifiers = this._editorIdentifiers.filter(editor => {
                return editor !== identifier;
            });

            await this.reference.update({
                editorIdentifiers: this._editorIdentifiers
            });
        }
    }

    public async addCompany(name: string) {
        const company: CompanyIdentity = {
            identifier: generateInternalIdentifier(),
            name: name
        };

        await this.reference.update({
            companies: [company, ...this._companies]
        });

        return company;
    }

    public async renameCompany(identifier: string, name: string) {
        const company = this._companies.find(company => company.identifier === identifier);

        if (company) {
            company.name = name;

            await this.reference.update({
                companies: this._companies
            });
        }
    }

    public isShared() {
        return this._editorIdentifiers.length > 1;
    }
}