import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import {inProduction} from "../util/helpers";

const firebaseConfig = {
    apiKey: "AIzaSyAwm308bQ8SDr8XUApUblx82s1axiQu_BY",
    authDomain: "sus-easybook.firebaseapp.com",
    databaseURL: "https://sus-easybook.firebaseio.com",
    projectId: "sus-easybook",
    storageBucket: "sus-easybook.appspot.com",
    messagingSenderId: "980529752266",
    appId: "1:980529752266:web:c20fe272537af440c8ec33",
    measurementId: "G-2PYES65RTB"
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();

(async () => {
    await firestore.enablePersistence();
})();

type ErrorCode = keyof typeof BaseDocument.ERRORS;

export default abstract class BaseDocument {
    public static readonly ERRORS = {
        "auth/email-already-in-use": "Diese E-Mail-Adresse ist schon registriert.",
        "auth/email-not-authenticated": "Bestätige deine E-Mail-Adresse, bevor Du dich anmeldest.",
        "auth/internal-error": "Es ist ein interner Fehler aufgetreten. Versuche es später erneut.",
        "auth/invalid-email": "Bitte gib eine echte E-Mail-Adresse an.",
        "auth/invalid-password": "Bitte nutze ein anderes Passwort.",
        "auth/network-request-failed": "Bitte prüfe deine Internetverbindung.",
        "auth/requires-recent-login": "Diese Aktion ist potenziell gefährlich. Logge dich aus und wieder ein, um sie durchzuführen.",
        "auth/user-not-found": "Zu dieser E-Mail-Adresse gibt es kein Profil.",
        "auth/wrong-password": "Das Passwort ist falsch.",
        inexistentEntry: "Dieser Eintrag konnte nicht gefunden werden.",
        inexistentProject: "Dieses Projekt konnte nicht gefunden werden.",
        inexistentUser: "Dieser Nutzer konnte nicht gefunden werden.",
        noLogin: "Bitte melde dich erst an.",
        unknown: "Es ist ein unbekannter Fehler aufgetreten.",
        userAlreadyEditor: "Das Projekt ist bereits für diesen Nutzer freigegeben."
    };

    public readonly identifier: string;
    protected readonly collection: string;

    protected constructor(collection: string, identifier: string) {
        this.collection = collection;
        this.identifier = identifier;
    }

    private _reference: firebase.firestore.DocumentReference | null = null;

    protected get reference() {
        if (!this._reference) {
            this._reference = firestore.collection(this.collection).doc(this.identifier);
        }

        return this._reference;
    }

    public static getErrorMessage(error: any) {
        if (!inProduction()) {
            console.error(error);
            return error.toString();
        } else if (Object.values(BaseDocument.ERRORS).includes(error.message)) {
            return error.message;
        } else {
            return BaseDocument.ERRORS[error.code as ErrorCode] || BaseDocument.ERRORS.unknown;
        }
    }

    protected static getDocument(collection: string, identifier: string) {
        return firestore.collection(collection).doc(identifier).get();
    }

    public abstract async update(...args: any[]): Promise<void>;

    public abstract async delete(): Promise<void>;
}