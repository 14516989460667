import React from "react";
import BaseInput, {BaseInputProps} from "./BaseInput";

type Props = BaseInputProps<string> & {
    type?: "text" | "email";
}

export default class TextInput extends BaseInput<string, Props> {
    protected readonly type = "text";
    protected readonly defaultValue = "";

    public constructor(props: Props) {
        super(props);

        this.state = {
            value: props.value || this.defaultValue,
            validation: this.initialValidation()
        };
    }

    protected renderInner() {
        return <input
            id={this.inputId}
            placeholder={this.props.placeholder}
            type={this.props.type || "text"}
            onChange={this.onChange}
            value={this.state.value}
        />;
    }

    protected onChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.update(event.target.value);
    }
}