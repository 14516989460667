import BaseSheet, {BaseSheetProps} from "./BaseSheet";
import React from "react";
import Validation from "../../util/validation";
import PasswordInput from "../inputs/PasswordInput";
import ProcessingButton from "../buttons/ProcessingButton";
import {ICONS, LABELS, PLACEHOLDERS} from "../../util/constants";
import NegativePopup from "../popup/NegativePopup";
import User from "../../data/User";
import BaseDocument from "../../data/BaseDocument";
import OutlinedIcon from "../icons/OutlinedIcon";

type Props = BaseSheetProps & {
    description: string;
    onConfirm: () => Promise<void>;
}

type State = {
    error: string;
    password: string;
}

export default class ReAuthenticationSheet extends BaseSheet<Props, State> {
    protected readonly type = "re-authenticate";

    public constructor(props: Props) {
        super(props);

        this.state = {
            error: "",
            password: ""
        };

        this.execute = this.execute.bind(this);
    }

    protected renderButtons() {
        return (
            <div>
                <button className="round" onClick={this.close}>
                    <OutlinedIcon name={ICONS.close}/>
                </button>

                <ProcessingButton
                    onClick={this.execute}
                    className="primary"
                >
                    bestätigen
                </ProcessingButton>
            </div>
        );
    }

    protected renderInner() {
        const onPasswordChange = (password: string) => {
            this.setState({
                password: password
            });
        }

        return (
            <form>
                <p>{this.props.description}</p>

                <PasswordInput
                    icon={ICONS.password}
                    placeholder={PLACEHOLDERS.password}
                    label={LABELS.password}
                    value={this.state.password}
                    validate={Validation.password}
                    onChange={onPasswordChange}
                />

                {this.renderError()}
            </form>
        );
    }

    private renderError() {
        const close = () => {
            this.setState({
                error: ""
            });
        }

        if (this.state.error) {
            return <NegativePopup message={this.state.error} onClose={close}/>;
        }
    }

    private async execute() {
        const validation = Validation.password(this.state.password);

        if (validation.valid) {
            await User.reAuthenticate(this.state.password).then(async () => {
                await this.props.onConfirm().catch(error => {
                    console.log(error);
                });
                this.close();
            }).catch(error => {
                this.setState({
                    error: BaseDocument.getErrorMessage(error)
                });
            });
        } else {
            this.setState({
                error: validation.message
            });
        }
    }
}