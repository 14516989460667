const BREAKPOINTS = {
    small: 480,
    medium: 900
} as const;

class Device {
    public get width() {
        return window.innerWidth;
    }

    public hasTouch() {
        return "ontouchstart" in window || navigator.msMaxTouchPoints > 0;
    }

    public isSmall() {
        return this.width < BREAKPOINTS.small;
    }

    public isMedium() {
        return !this.isSmall() && this.width < BREAKPOINTS.medium;
    }

    public isLarge() {
        return !this.isSmall() && !this.isMedium();
    }
}

const device = new Device();
export default device;