import React from "react";
import BaseComponent, {BaseProps} from "../BaseComponent";

type Option = {
    identifier: string;
    label: string;
    disabled?: boolean;
}

export type BaseSelectorProps<DataType> = BaseProps & {
    options: Option[];
    onChange: (selected: DataType) => void;
    selected?: DataType;
    onEdit?: (identifier: string, label: string) => Promise<void>;
}

export type BaseSelectorState<DataType> = {
    selected: DataType;
}

export default abstract class BaseSelector<DataType extends string | null | string[], Props extends BaseSelectorProps<DataType> = BaseSelectorProps<DataType>, State extends BaseSelectorState<DataType> = BaseSelectorState<DataType>> extends BaseComponent<Props, State> {
    protected abstract readonly type: string;

    protected constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <div id={this.id} className={this.classes("selector", this.type)}>
                {this.renderInner()}
            </div>
        );
    }

    protected abstract renderInner(): React.ReactNode | React.ReactNode[];

    protected abstract onChange(identifier: string, selected: boolean): void;

    protected abstract isSelected(identifier: string): boolean;

    protected update(selected: DataType) {
        this.setState({
            selected: selected
        });

        this.props.onChange(selected);
    }

    protected isDisabled(identifier: string) {
        for (const option of this.props.options) {
            if (option.identifier === identifier) {
                return option.disabled;
            }
        }

        return false;
    }

    protected onEdit(identifier: string) {
        if (this.props.onEdit) {
            return (label: string) => this.props.onEdit!(identifier, label);
        } else {
            return undefined;
        }
    }
}