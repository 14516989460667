import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {ROUTES} from "./util/constants";
import PrivacyPage from "./pages/Privacy";
import ImprintPage from "./pages/Imprint";
import SignInPage from "./pages/SignIn";
import SignUpPage from "./pages/SignUp";
import SettingsPage from "./pages/Settings";
import Navigation from "./components/sections/Navigation";
import Footer from "./components/sections/Footer";
import ForgotPassword from "./pages/ForgotPassword";
import ProjectOverviewPage from "./pages/ProjectOverview";
import NewProject from "./pages/NewProject";
import EditProject from "./pages/EditProject";
import EntryOverview from "./pages/EntryOverview";
import ExportPage from "./pages/ExportPage";

function App() {
    return (
        <div className="App">
            <Navigation/>
            <BrowserRouter>
                <Switch>
                    <Route path={ROUTES.imprint} exact component={ImprintPage}/>
                    <Route path={ROUTES.privacy} exact component={PrivacyPage}/>
                    <Route path={ROUTES.signIn} exact component={SignInPage}/>
                    <Route path={ROUTES.signUp} exact component={SignUpPage}/>
                    <Route path={ROUTES.forgotPassword} exact component={ForgotPassword}/>
                    <Route path={ROUTES.settings} exact component={SettingsPage}/>
                    <Route path={[ROUTES.start, ROUTES.projects]} exact component={ProjectOverviewPage}/>
                    <Route path={ROUTES.editProject} exact component={EditProject}/>
                    <Route path={ROUTES.newProject} exact component={NewProject}/>
                    <Route path={ROUTES.project} exact component={EntryOverview}/>
                    <Route path={ROUTES.export} exact component={ExportPage}/>
                </Switch>
            </BrowserRouter>
            <Footer/>
        </div>
    );
}

export default App;