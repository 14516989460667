import React from "react";
import OutlinedIcon from "../icons/OutlinedIcon";
import {CookieConfiguration} from "../../util/cookies";
import BaseDialog, {BaseDialogProps} from "./BaseDialog";
import CheckBoxSelector from "../selectors/CheckBoxSelector";
import {generateClassName} from "../../util/generate";
import device from "../../util/device";
import {ICONS} from "../../util/constants";

const DISCLAIMER = `
    Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren und die Zugriffe auf unsere Website zu
    analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für Analysen
    weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen
    bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben.
` as const;

type State = {
    showConfiguration: boolean;
    configuration: CookieConfiguration;
}

export default class CookieDialog extends BaseDialog<BaseDialogProps, State> {
    protected type = "cookies";

    public constructor(props: BaseDialogProps) {
        super(props);

        this.state = {
            showConfiguration: false,
            configuration: CookieConfiguration.reset()
        };

        this.accept = this.accept.bind(this);
        this.toggle = this.toggle.bind(this);
        this.update = this.update.bind(this);
    }

    private static renderDescription() {
        return (
            <p className="decent">
                {DISCLAIMER}
            </p>
        );
    }

    protected renderInner() {
        return (
            <div>
                {this.state.showConfiguration ? this.renderConfiguration() : CookieDialog.renderDescription()}

                <div className="buttons">
                    <button
                        onClick={this.accept}
                        className={generateClassName("primary", device.isSmall() ? "small" : "")}
                    >
                        {this.state.showConfiguration ? "akzeptieren" : "alle akzeptieren"}
                    </button>

                    {this.renderToggleButton()}
                </div>
            </div>
        );
    }

    private renderConfiguration() {
        const cookies = this.state.configuration.all();
        return (
            <div>
                <CheckBoxSelector
                    options={cookies.map(cookie => {
                        return {
                            identifier: cookie.identifier,
                            disabled: cookie.required,
                            label: cookie.name
                        }
                    })}
                    onChange={this.update}
                    selected={cookies.filter(cookie => cookie.accepted).map(cookie => cookie.identifier)}
                />
            </div>
        );
    }

    private renderToggleButton() {
        if (!this.state.showConfiguration) {
            return (
                <button
                    onClick={this.toggle}
                    className={generateClassName("round", device.isSmall() ? "small" : "")}
                >
                    <OutlinedIcon name={ICONS.settings}/>
                </button>
            );
        }
    }

    private update(identifiers: string[]) {
        const config = this.state.configuration;
        config.accept(...identifiers);

        this.setState({
            configuration: config
        });
    }

    private accept() {
        this.state.configuration.save();
        this.close();
    }

    private toggle() {
        this.setState({
            showConfiguration: !this.state.showConfiguration
        });
    }
}