import React from "react";
import EmptyIllustration from "../../assets/images/empty.svg";
import BaseComponent, {BaseProps} from "../BaseComponent";
import device from "../../util/device";

type Props = BaseProps & {
    title?: string;
    description?: string;
    action?: {
        label: string;
        onClick: () => void;
    }
}

export default class Empty extends BaseComponent<Props> {
    public componentDidMount() {
        this.fadeIn().now();
    }

    public render() {
        return (
            <div id={this.id} className={this.classes("empty")}>
                <img src={EmptyIllustration} alt="keine Inhalte"/>
                {this.renderContent()}
            </div>
        );
    }

    private renderContent() {
        return (
            <div className="content">
                {this.props.title && !device.isSmall() ? <h1>{this.props.title}</h1> : null}
                {this.props.description && !device.isSmall() ? <p>{this.props.description}</p> : null}
                {this.renderAction()}
            </div>
        );
    }

    private renderAction() {
        if (this.props.action) {
            return (
                <button className="primary" type="button" onClick={this.props.action.onClick}>
                    {this.props.action.label}
                </button>
            );
        }
    }
}