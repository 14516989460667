import React from "react";
import BaseComponent, {BaseProps} from "../BaseComponent";
import {ICONS, ROUTES} from "../../util/constants";
import OutlinedIcon from "../icons/OutlinedIcon";
import {IconName, Route} from "../../util/types";
import {generateClassName, generateKey} from "../../util/generate";
import {currentRoute} from "../../util/helpers";
import SolidIcon from "../icons/SolidIcon";
import HintCard from "../indicators/HintCard";
import device from "../../util/device";
import Logo from "../../assets/images/logo.svg";
import User from "../../data/User";
import {auth} from "../../data/BaseDocument";

const HINTS = {
    [ROUTES.signIn]: [
        "Wenn Du noch nicht registriert bist, musst du erst ein Profil erstellen, bevor Du dich anmelden kannst.",
    ],
    [ROUTES.signUp]: [
        "Wenn du schon ein Konto hast, kannst du dich anmelden.",
    ],
    [ROUTES.privacy]: [
        "Du kannst alle zu dir gespeicherten Daten jederzeit in den Einstellungen löschen.",
    ],
    [ROUTES.imprint]: [
        "Weitere Informationen findest Du in der Datenschutzerklärung.",
    ],
    [ROUTES.projects]: [
        "Du kannst deine Projekte für andere easybook Nutzer freigeben, um mit ihnen zusammenzuarbeiten.",
        device.isSmall() ? "Klicke auf ein Projekt, um es zu öffnen." : "Doppelklicke auf ein Projekt, um es zu öffnen.",
        device.isSmall() ? "Halte ein projekt gedrückt für mehr Optionen." : "Klicke auf ein Projekt für mehr Optionen."
    ],
    [ROUTES.project]: [
        device.isSmall() ? "Klicke auf einen Eintrag, um mehr Details zu sehen." : "Doppelklicke auf einen Eintrag, um mehr Details zu sehen.",
    ],
    [ROUTES.createProject]: [
        "Du kannst den Titel und den Bauherrn später immer noch anpassen."
    ]
} as { [key in string]: string[] };

type LinkProps = {
    route: Route;
    icon: IconName;
    name: string;
}

function Link(props: LinkProps) {
    const isActive = currentRoute() === props.route;

    function renderIcon() {
        if (isActive) {
            return <SolidIcon name={props.icon} className="centered big"/>;
        } else {
            return <OutlinedIcon name={props.icon} className="centered big"/>;
        }
    }

    return (
        <a href={props.route} className={generateClassName("link", isActive ? "active" : "")}>
            {renderIcon()}

            <span className="name">
                {props.name}
            </span>
        </a>
    );
}

type NavigationState = {
    signedIn: boolean;
}

export default class Navigation extends BaseComponent<BaseProps, NavigationState> {
    public constructor(props: BaseProps) {
        super(props);

        this.state = {
            signedIn: User.isSignedIn()
        };
    }

    public componentDidMount() {
        auth.onAuthStateChanged(user => {
            this.setState({
                signedIn: user !== null
            });
        });
    }

    private static renderHeader() {
        if (!device.isSmall()) {
            return (
                <div className="header">
                    <img src={Logo} alt="easybook Logo"/>
                    <h1>easybook</h1>
                </div>
            );
        }
    }

    private static renderMinorLinks() {
        if (device.isLarge()) {
            return (
                <div className="minor">
                    <Link route={ROUTES.imprint} icon={ICONS.imprint} name="Impressum"/>
                    <Link route={ROUTES.privacy} icon={ICONS.privacy} name="Datenschutz"/>
                </div>
            );
        }
    }

    private static renderHint() {
        if (device.isLarge()) {
            const currentParts = currentRoute().split("/");
            for (const route in HINTS) {
                const parts = route.split("/");

                if (parts.length === currentParts.length && parts.every((part, index) => {
                    return part[0] === ":" || part === currentParts[index];
                })) {
                    const hints = HINTS[route];
                    if (hints) {
                        const index = Math.floor(Math.random() * hints.length)
                        return <HintCard title="Tipp!" text={hints[index]}/>;
                    }
                }
            }
        }
    }

    public render() {
        return (
            <nav id={this.id} className={this.classes()}>
                {Navigation.renderHeader()}
                <div className="links">
                    {this.renderMajorLinks()}
                    {Navigation.renderMinorLinks()}
                </div>
            </nav>
        );
    }

    private renderMajorLinks() {
        let links: LinkProps[];

        if (this.state.signedIn) {
            links = [
                {route: ROUTES.projects, icon: ICONS.projects, name: "Projekte"},
                {route: ROUTES.settings, icon: ICONS.settings, name: "Einstellungen"}
            ];
        } else {
            links = [
                {route: ROUTES.signIn, icon: ICONS.signIn, name: "Anmeldung"},
                {route: ROUTES.signUp, icon: ICONS.signUp, name: "Registrierung"}
            ];
        }

        return (
            <div className="major">
                {links.map(link => <Link route={link.route} icon={link.icon} name={link.name} key={generateKey()}/>)}
                {Navigation.renderHint()}
            </div>
        );
    }
}