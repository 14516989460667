import React from "react";
import {ICONS} from "../../util/constants";
import SolidIcon from "../icons/SolidIcon";
import BaseToggleable from "./BaseToggleable";

export default class CheckBox extends BaseToggleable {
    protected readonly type = "check-box";


    protected renderBox() {
        if (this.state.toggled) {
            return <SolidIcon name={ICONS.checked}/>;
        }
    }
}