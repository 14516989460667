export const ID_LENGTH = 8;
export const KEY_LENGTH = 16;
export const INTERNAL_IDENTIFIER_LENGTH = 12;
export const IMAGE_IDENTIFIER_LENGTH = 32;
export const TRANSITION_DURATION = 280;
export const MIN_VALIDATION_DELAY = 800;
export const MAX_POPUP_VISIBLE_TIME = 6000;
export const MIN_PASSWORD_LENGTH = 8;
export const MIN_PROCESSING_TIME = 500;
export const DOUBLE_CLICK_DURATION = 280;
export const HOLD_ACTION_DURATION = 750;

export const ICONS = {
    add: "add",
    back: "chevron_left",
    builder: "engineering",
    company: "engineering",
    checked: "done",
    export: "save_alt",
    close: "close",
    delete: "delete",
    collapse: "expand_less",
    date: "today",
    decrease: "remove",
    edit: "edit",
    email: "alternate_email",
    imprint: "account_balance",
    increase: "add",
    information: "info",
    invalid: "close",
    invisible: "visibility_off",
    mailReference: "attach_email",
    more: "expand_more",
    name: "face",
    next: "chevron_right",
    note: "notes",
    notification: "notifications",
    open: "launch",
    ownedProject: "folder",
    password: "vpn_key",
    place: "room",
    privacy: "privacy_tip",
    projects: "home_work",
    remove: "remove_circle",
    save: "check",
    settings: "settings",
    share: "person_add",
    sharedProject: "folder_shared",
    signIn: "login",
    signUp: "person_add",
    temperature: "ac_unit",
    time: "access_time",
    title: "title",
    valid: "done",
    visible: "visibility",
    weather: "ac_unit",
    workers: "group",
} as const;

export const ICON_TYPES = {
    solid: "solid",
    outlined: "outlined"
} as const;

export const PLACEHOLDERS = {
    builder: "Muster GmbH",
    chooseFormat: "Format wählen",
    email: "max@mustermann.de",
    imageNote: "Notiz",
    mailReference: "Brief vom 01.01.",
    name: "Max Mustermann",
    newCompany: "Muster GmbH",
    note: "besondere Vorkommnisse etc.",
    password: "********",
    projectTitle: "Projekt XY",
    temperature: "15",
    weather: "Witterung",
    workers: "7",
    workNote: "ausgeführte Arbeiten etc.",
    workPlace: "3. OG"
} as const;

export const LABELS = {
    builder: "Name des Bauherrn",
    date: "Datum",
    duration: "Zeitraum (von bis)",
    email: "E-Mail-Adresse",
    mailReference: "Verweis auf Schriftverkehr (optional)",
    name: "Vollständiger Name",
    newCompany: "Neue Firma hinzufügen",
    newPassword: "Neues Passwort",
    note: "Notiz (optional)",
    password: "Passwort",
    projectTitle: "Bezeichnung des Projekts",
    shareByEmail: "E-Mail-Adresse des Mitarbeiters",
    temperature: "Temperatur in °C",
    workers: "Anzahl der Mitarbeiter",
    workPlace: "Ausführungsort (optional)"
} as const;

export const ROUTES = {
    createProject: "/neu/projekt",
    editProject: "/bearbeiten/projekt/:id",
    export: "/exportieren",
    forgotPassword: "/vergessen",
    imprint: "/impressum",
    newProject: "/neu/projekt",
    privacy: "/datenschutz",
    projects: "/projekte",
    project: "/projekt/:id",
    settings: "/einstellungen",
    signIn: "/anmelden",
    signUp: "/registrieren",
    start: "/"
} as const;

export const WEATHER = {
    sunny: "sonnig",
    cloudy: "bewölkt",
    rainy: "regnerisch",
    snowy: "verschneit",
    stormy: "stürmisch"
} as const;

export const EXPORT_FORMATS = {
    default: "Standard SUS Format",
    dortmund: "Dortmund Format"
} as const;

export const OPERATOR = {
    name: "Lukas Haverbeck",
    street: "Eifelstraße",
    houseNumber: "1C",
    postCode: "52068",
    city: "Aachen",
    country: "Deutschland",
    email: "lukas.haverbeck@gmail.com",
    phoneNumber: "+49 1575 4825830"
} as const;

export const STORAGE_PATHS = {
    entryImages: "entry-images",
    signatureImages: "user-signatures"
} as const;