import React from "react";
import SettingsForm from "../components/forms/SettingsForm";
import User from "../data/User";

export default function SettingsPage() {
    User.redirectSignedOut();

    return (
        <main>
            <SettingsForm title="Einstellungen" actionLabel="speichern" className="wide major"/>
        </main>
    );
}