import BaseInput, {BaseInputProps, BaseInputState} from "./BaseInput";
import React from "react";
import {ICONS} from "../../util/constants";
import OutlinedIcon from "../icons/OutlinedIcon";

type State = BaseInputState<string> & {
    obscure: boolean;
}

export default class PasswordInput extends BaseInput<string, BaseInputProps<string>, State> {
    protected readonly type = "password";
    protected readonly defaultValue = "";

    public constructor(props: BaseInputProps<string>) {
        super(props);

        this.state = {
            value: props.value || "",
            validation: this.initialValidation(),
            obscure: true
        };

        this.toggle = this.toggle.bind(this);
    }

    protected renderInner(): React.ReactNode {
        return (
            <div>
                <input
                    id={this.inputId}
                    type={this.state.obscure ? "password" : "text"}
                    placeholder={this.props.placeholder}
                    onChange={this.onChange}
                    value={this.state.value}
                />

                <div className="buttons">
                    <button type="button" onClick={this.toggle} tabIndex={-1}>
                        <OutlinedIcon name={this.state.obscure ? ICONS.visible : ICONS.invisible}/>
                    </button>
                </div>
            </div>
        )
    }

    protected onChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.update(event.target.value);
    }

    private toggle() {
        this.setState({
            obscure: !this.state.obscure
        });
    }
}