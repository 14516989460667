import React from "react";
import BaseForm, {BaseFormProps} from "./BaseForm";
import TextInput from "../inputs/TextInput";
import {ICONS, LABELS, PLACEHOLDERS, ROUTES} from "../../util/constants";
import Validation from "../../util/validation";
import PasswordInput from "../inputs/PasswordInput";
import {redirect} from "../../util/helpers";
import CheckBox from "../toggleables/CheckBox";
import User from "../../data/User";
import BaseDocument from "../../data/BaseDocument";

type Data = {
    name: string;
    email: string;
    password: string;
    acceptPrivacyConditions: boolean;
}

export default class SignUpForm extends BaseForm<Data> {
    public constructor(props: BaseFormProps) {
        super(props);

        this.state = {
            message: "",
            processing: false,
            error: "",
            data: {
                name: "",
                email: "",
                password: "",
                acceptPrivacyConditions: false
            }
        };

        this.onNameChange = this.onNameChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onAcceptPrivacyConditionsChange = this.onAcceptPrivacyConditionsChange.bind(this);
    }

    protected renderInputs() {
        return (
            <div>
                <TextInput
                    placeholder={PLACEHOLDERS.name}
                    icon={ICONS.name}
                    label={LABELS.name}
                    validate={Validation.fullName}
                    onChange={this.onNameChange}
                    value={this.state.data.name}
                />

                <TextInput
                    type="email"
                    placeholder={PLACEHOLDERS.email}
                    icon={ICONS.email}
                    label={LABELS.email}
                    validate={Validation.email}
                    onChange={this.onEmailChange}
                    value={this.state.data.email}
                />

                <PasswordInput
                    placeholder={PLACEHOLDERS.password}
                    icon={ICONS.password}
                    label={LABELS.password}
                    validate={Validation.password}
                    onChange={this.onPasswordChange}
                    value={this.state.data.password}
                />

                <CheckBox
                    label="Ich akzeptiere den Datenschutz."
                    labelElement={<span>Ich akzeptiere den <a href={ROUTES.privacy}>Datenschutz</a>.</span>}
                    onChange={this.onAcceptPrivacyConditionsChange}
                    toggled={this.state.data.acceptPrivacyConditions}
                />
            </div>
        );
    }

    protected renderButtons(): React.ReactNode {
        return (
            <button onClick={() => redirect(ROUTES.signIn)} className="secondary">
                schon registriert?
            </button>
        );
    }

    protected validate() {
        return [
            Validation.fullName(this.state.data.name),
            Validation.email(this.state.data.email),
            Validation.password(this.state.data.password),
            Validation.acceptPrivacyConditions(this.state.data.acceptPrivacyConditions)
        ];
    }

    protected async submit() {
        const result = {
            error: false,
            message: ""
        };

        await User.signUp(this.state.data.name, this.state.data.email, this.state.data.password).catch(error => {
            result.error = true;
            result.message = BaseDocument.getErrorMessage(error);
        });

        return result;
    }

    private onNameChange(name: string) {
        const data = this.state.data;
        data.name = name;
        this.update(data);
    }

    private onEmailChange(email: string) {
        const data = this.state.data;
        data.email = email;
        this.update(data);
    }

    private onPasswordChange(password: string) {
        const data = this.state.data;
        data.password = password;
        this.update(data);
    }

    private onAcceptPrivacyConditionsChange(accept: boolean) {
        const data = this.state.data;
        data.acceptPrivacyConditions = accept;
        this.update(data);
    }
}