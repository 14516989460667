import React from "react";
import BaseInput, {BaseInputProps} from "./BaseInput";

type Props = BaseInputProps<string> & {
    type?: "text" | "email";
}

export default class TextFieldInput extends BaseInput<string, Props> {
    protected readonly type = "text-field";
    protected readonly defaultValue = "";

    public constructor(props: Props) {
        super(props);

        this.state = {
            value: props.value || this.defaultValue,
            validation: this.initialValidation()
        };
    }

    protected renderInner() {
        return <textarea placeholder={this.props.placeholder} onChange={this.onChange} value={this.state.value}/>;
    }

    protected onChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.update(event.target.value);
    }
}