import React from "react";
import BaseComponent, {BaseProps} from "../BaseComponent";
import device from "../../util/device";
import {DOUBLE_CLICK_DURATION, HOLD_ACTION_DURATION} from "../../util/constants";
import {actionVibration} from "../../util/helpers";

export type BaseItemCardProps = BaseProps & {
    identifier: string;
    selected: boolean;
    onClick: () => void;
    onDoubleClick: () => void;
    onHold: () => void;
}

export default abstract class BaseItemCard<Props extends BaseItemCardProps = BaseItemCardProps> extends BaseComponent<Props> {
    protected abstract readonly type: string;
    private touching = false;
    private clickTimeout: NodeJS.Timeout | null = null;

    public constructor(props: Props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
    }

    public render() {
        return (
            <div
                onClick={this.onClick}
                onTouchStart={this.onTouchStart}
                onMouseDown={this.onTouchStart}
                onTouchEnd={() => this.touching = false}
                onMouseUp={() => this.touching = false}
                className={this.classes("item-card", this.type, this.props.selected ? "selected" : "")}
            >
                {this.renderInner()}
            </div>
        );
    }

    protected abstract renderInner(): React.ReactNode;

    private onClick() {
        if (this.clickTimeout !== null) {
            if (!device.hasTouch()) {
                this.props.onDoubleClick();
                this.clearTimeout();
            }
        } else {
            this.clickTimeout = setTimeout(() => {
                this.props.onClick();
                this.clearTimeout();
            }, DOUBLE_CLICK_DURATION);
        }
    }

    private onTouchStart() {
        if (device.hasTouch()) {
            this.touching = true;

            setTimeout(() => {
                if (this.touching) {
                    actionVibration();
                    this.props.onHold();
                }
            }, HOLD_ACTION_DURATION);
        }
    }

    private clearTimeout() {
        if (this.clickTimeout !== null) {
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
        }
    }
}