import React from "react";
import BaseForm, {BaseFormProps} from "./BaseForm";
import TextInput from "../inputs/TextInput";
import {ICONS, LABELS, PLACEHOLDERS, ROUTES} from "../../util/constants";
import Validation from "../../util/validation";
import PasswordInput from "../inputs/PasswordInput";
import {redirect} from "../../util/helpers";
import User from "../../data/User";
import BaseDocument from "../../data/BaseDocument";

type Data = {
    email: string;
    password: string;
}

export default class SignInForm extends BaseForm<Data> {
    public constructor(props: BaseFormProps) {
        super(props);

        this.state = {
            message: "",
            processing: false,
            error: "",
            data: {
                email: "",
                password: ""
            }
        };

        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
    }

    protected renderInputs(): React.ReactNode {
        return (
            <div>
                <TextInput
                    type="email"
                    placeholder={PLACEHOLDERS.email}
                    icon={ICONS.email}
                    label={LABELS.email}
                    validate={Validation.email}
                    onChange={this.onEmailChange}
                    value={this.state.data.email}
                />

                <PasswordInput
                    placeholder={PLACEHOLDERS.password}
                    icon={ICONS.password}
                    label={LABELS.password}
                    validate={Validation.password}
                    onChange={this.onPasswordChange}
                    value={this.state.data.password}
                />
            </div>
        );
    }

    protected renderButtons(): React.ReactNode {
        return (
            <div>
                <button onClick={() => redirect(ROUTES.signUp)} className="secondary">
                    noch kein Konto?
                </button>

                <button onClick={() => redirect(ROUTES.forgotPassword)} className="transparent">
                    Passwort vergessen?
                </button>
            </div>
        );
    }

    protected validate() {
        return [
            Validation.email(this.state.data.email),
            Validation.password(this.state.data.password)
        ];
    }

    protected async submit() {
        const result = {
            error: false,
            message: ""
        };

        await User.signIn(this.state.data.email, this.state.data.password).catch(error => {
            result.error = true;
            result.message = BaseDocument.getErrorMessage(error);
        });

        return result;
    }

    private onEmailChange(email: string) {
        const data = this.state.data;
        data.email = email;
        this.update(data);
    }

    private onPasswordChange(password: string) {
        const data = this.state.data;
        data.password = password;
        this.update(data);
    }
}