import BaseSelector, {BaseSelectorProps, BaseSelectorState} from "./BaseSelector";

export default abstract class MultipleChoiceSelector<Props extends BaseSelectorProps<string[]> = BaseSelectorProps<string[]>, State extends BaseSelectorState<string[]> = BaseSelectorState<string[]>> extends BaseSelector<string[], Props, State> {
    protected onChange(identifier: string, selected: boolean): void {
        if (!this.isDisabled(identifier)) {
            if (this.isSelected(identifier)) {
                if (!selected) {
                    this.update(this.state.selected.filter(option => option !== identifier));
                }
            } else {
                this.update([...this.state.selected, identifier]);
            }
        }
    }

    protected isSelected(identifier: string) {
        return this.state.selected.includes(identifier);
    }
}